import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { useAtomValue } from 'jotai';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';
import useSearchParamsState from '../../utils/hooks/useSearchParamsState';
import Page from '../../atoms/Page';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import TestCategory from './TestCategory';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import ComboboxMulti from '../../atoms/ComboboxMulti';
import './AccuracyScorePage.scss';

const TestCaseFilterOption = Object.freeze({
  ALL: 'all',
  ACCEPTED: 'accepted',
  DISQUALIFIED: 'disqualified',
});

const TEST_CATEGORIES_LIST_GQL = gql`
  query TestCategoryQuery {
    test_category(where: { deleted_at: { _is_null: true } }) {
      id
      name
      selected
    }
  }
`;

function AccuracyScorePage({ titleSegments = [] }) {
  const algorithmVersion = useAtomValue(algorithmVersionAtom);
  const [sha, setSha] = useSearchParamsState('sha', algorithmVersion);
  const [testCaseFilter, setTestCaseFilter] = useSearchParamsState('filter', TestCaseFilterOption.ACCEPTED);
  const [selectedTestCategoryIds, setSelectedTestCategoryIds] = useSearchParamsState('c', []);

  const { loading, data, error } = useQuery(TEST_CATEGORIES_LIST_GQL);

  useEffect(() => {
    // Adding as a use effect as if you go directly to this page the atom value isn't set yet
    if (algorithmVersion && !sha) {
      setSha(algorithmVersion);
    }
  }, [algorithmVersion]);

  const pageTitleSegments = useMemo(() => ['Accuracy Score Page', ...titleSegments], []);
  const testCaseFilterOptionList = [
    {
      id: TestCaseFilterOption.ALL,
      name: 'All',
      selected: testCaseFilter === TestCaseFilterOption.ALL,
    },
    {
      id: TestCaseFilterOption.ACCEPTED,
      name: 'Accepted Only',
      selected: testCaseFilter === TestCaseFilterOption.ACCEPTED,
    },
    {
      id: TestCaseFilterOption.DISQUALIFIED,
      name: 'Disqualified Only',
      selected: testCaseFilter === TestCaseFilterOption.DISQUALIFIED,
    },
  ];

  const categoryCheckboxItems = data?.test_category.map((testCategory) => {
    return {
      id: testCategory.id,
      name: testCategory.name,
      selected: selectedTestCategoryIds.includes(testCategory.id),
    };
  });

  const onChangeCategorySelection = (updatedList) => {
    const newSelectedTestCategoryIds = updatedList.map((i) => i.id);
    setSelectedTestCategoryIds(newSelectedTestCategoryIds);
  };

  const onFilterDropDownChange = (item) => {
    setTestCaseFilter(item.id);
  };

  if (!sha) {
    return <div>SHA Missing</div>;
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <Page className="AccuracyScorePage" heading="Accuracy Score Page" titleSegments={pageTitleSegments}>
      <div className="AccuracyScorePage-upperContainer">
        <div className="AccuracyScorePage-controls">
          <FeedFloTextInput
            label="Algorithm Sha:"
            text={sha}
            onChange={(t) => {
              setSha(t);
            }}
          />
          <ComboboxMulti
            label="Categories:"
            items={categoryCheckboxItems}
            onChange={onChangeCategorySelection}
            value={selectedTestCategoryIds}
          />
          <FeedFloDropDown list={testCaseFilterOptionList} label="Filter Cases:" onChange={onFilterDropDownChange} />
        </div>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="CategoryHolder">
            {selectedTestCategoryIds.map((id) => (
              <TestCategory sha={sha} categoryId={id} key={id} testCaseFilter={testCaseFilter}></TestCategory>
            ))}
          </div>
        </>
      )}
    </Page>
  );
}

AccuracyScorePage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default AccuracyScorePage;
