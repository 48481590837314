import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './DateTimeInput.scss';

function DateTimeInput({
  loading = false,
  inputRef = null,
  className = '',
  labelClassName = '',
  inputClassName = '',
  descriptionClassName = '',
  errorClassName = '',
  error = null,
  tabIndex = 0,
  isValid = true,
  disabled = false,
  name = '',
  label = '',
  placeholder = '',
  value = '',
  description = '',
  showTime = true,
  onChange = () => {},
  onBlur = () => {},
}) {
  if (loading) {
    return (
      <div className={`DateTimeInput ${className}`}>
        {label ? <LoadingSkeleton className="DateTimeInput-labelText--loader" /> : null}
        <LoadingSkeleton className="DateTimeInput-input--loader" />
        {description ? <LoadingSkeleton className="DateTimeInput-description--loader" /> : null}
      </div>
    );
  }

  return (
    <div className={`DateTimeInput ${disabled ? 'DateTimeInput--disabled' : ''} ${className}`}>
      {label && (
        <label
          className={`DateTimeInput-labelText ${!isValid ? 'DateTimeInput-labelText--error' : ''} ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <input
        ref={inputRef}
        type={showTime ? 'datetime-local' : 'date'}
        className={`DateTimeInput-input ${!isValid ? 'DateTimeInput-input--error' : ''} ${inputClassName}`}
        tabIndex={tabIndex}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {description && (
        <div
          className={`DateTimeInput-description ${
            !isValid ? 'DateTimeInput-description--error' : ''
          } ${descriptionClassName}`}
        >
          {description}
        </div>
      )}
      {!isValid && <div className={`DateTimeInput-error ${errorClassName}`}>{error}</div>}
    </div>
  );
}

DateTimeInput.propTypes = {
  loading: PropTypes.bool,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  showTime: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

export default DateTimeInput;
