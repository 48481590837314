import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import FeedDeskSettingsView from './FeedDeskSettingsView';
import { FARM_GQL } from '../NotificationSettings/queries';
import { FeedDeskEmailFormat } from './enum';
import { isEqual } from 'lodash';

const SETTINGS_UPDATE_GQL = gql`
  mutation FeedDeskEmailSettingsMutation($settings: feed_desk_email_settings_insert_input!) {
    insert_feed_desk_email_settings(
      objects: [$settings]
      on_conflict: {
        constraint: feed_desk_email_settings_user_id_key
        update_columns: [
          all_barns
          barn_ids
          email_format
          enabled
          send_empty_emails
          send_time_seconds
          send_lead_time_days
        ]
      }
    ) {
      affected_rows
    }
  }
`;

const SETTINGS_GQL = gql`
  query FeedDeskEmailSettingsQuery {
    feed_desk_email_settings {
      all_barns
      barn_ids
      email_format
      enabled
      send_empty_emails
      send_time_seconds
      send_lead_time_days
    }
  }
`;

const FeedDeskSettingsController = forwardRef(({ onChange }, ref) => {
  const { loading: farmLoading, data: farmData } = useQuery(FARM_GQL, { fetchPolicy: 'no-cache' });

  const [updateFeedDeskSettings, { loading: updateLoading }] = useMutation(SETTINGS_UPDATE_GQL, {
    refetchQueries: [SETTINGS_GQL],
  });

  const { loading: settingsLoading, data: settingsData } = useQuery(SETTINGS_GQL, { fetchPolicy: 'no-cache' });

  const [emailsEnabled, setEmailsEnabled] = useState(false);
  const [emailTimeHour, setEmailTimeHour] = useState(6);
  const [allBarnsSelected, setAllBarnsSelected] = useState(true);
  const [selectedBarns, setSelectedBarns] = useState([]);
  const [emailFormat, setEmailFormat] = useState(FeedDeskEmailFormat.Digest);
  const [emptyEmails, setEmptyEmails] = useState(false);
  const [sendLeadTimeDays, setSendLeadTimeDays] = useState(3);
  useEffect(() => {
    const settings = settingsData?.feed_desk_email_settings?.[0];
    if (settings) {
      setEmailTimeHour(settings.send_time_seconds / (60 * 60));
      setEmailsEnabled(settings.enabled);
      setAllBarnsSelected(settings.all_barns);
      setSelectedBarns(settings.barn_ids);
      setEmailFormat(settings.email_format);
      setEmptyEmails(settings.send_empty_emails);
      setSendLeadTimeDays(settings.send_lead_time_days);
    }
  }, [settingsData]);

  const onChangeEmailsEnabled = (enabled) => {
    setEmailsEnabled(enabled);
    onChange(true);
  };
  const onChangeEmailTimeHour = (h) => {
    setEmailTimeHour(h);
    onChange(true);
  };
  const onChangeAllBarnsSelected = (enabled) => {
    setAllBarnsSelected(enabled);
    onChange(true);
  };
  const onChangeSelectedBarns = (ids) => {
    if (!isEqual(selectedBarns, ids)) {
      setSelectedBarns(ids);
      onChange(true);
    }
  };
  const onChangeEmailFormat = (f) => {
    setEmailFormat(f);
    onChange(true);
  };
  const onChangeEmptyEmails = (enabled) => {
    setEmptyEmails(enabled);
    onChange(true);
  };
  const onSendLeadTimeDays = (days) => {
    setSendLeadTimeDays(days);
    onChange(true);
  };

  useImperativeHandle(ref, () => ({
    save: async () => {
      updateFeedDeskSettings({
        variables: {
          settings: {
            all_barns: allBarnsSelected,
            barn_ids: selectedBarns,
            email_format: emailFormat,
            enabled: emailsEnabled,
            send_empty_emails: emptyEmails,
            send_time_seconds: emailTimeHour * 60 * 60,
            send_lead_time_days: sendLeadTimeDays,
          },
        },
      });
    },
  }));

  return (
    <FeedDeskSettingsView
      loading={farmLoading || updateLoading || settingsLoading}
      emailsEnabled={emailsEnabled}
      emailTimeHour={emailTimeHour}
      allBarnsSelected={allBarnsSelected}
      selectedBarns={selectedBarns}
      emailFormat={emailFormat}
      emptyEmails={emptyEmails}
      sendLeadTimeDays={sendLeadTimeDays}
      allBarns={farmData?.farm}
      onChangeEmailsEnabled={onChangeEmailsEnabled}
      onChangeEmailTimeHour={onChangeEmailTimeHour}
      onChangeAllBarnsSelected={onChangeAllBarnsSelected}
      onChangeSelectedBarns={onChangeSelectedBarns}
      onChangeEmailFormat={onChangeEmailFormat}
      onChangeEmptyEmails={onChangeEmptyEmails}
      onSendLeadTimeDays={onSendLeadTimeDays}
    />
  );
});
FeedDeskSettingsController.displayName = 'FeedDeskSettingsController';
FeedDeskSettingsController.propTypes = {
  onChange: PropTypes.func,
};

export default FeedDeskSettingsController;
