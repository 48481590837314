import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ExportController from './ExportController';
import Page from '../../atoms/Page';
import './ExportPage.scss';

function ExportPage({ titleSegments = [] }) {
  const pageTitleSegments = useMemo(() => ['Exports', ...titleSegments], []);

  return (
    <Page className="ExportPage" heading="Data Export" titleSegments={pageTitleSegments}>
      <ExportController />
    </Page>
  );
}

ExportPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default ExportPage;
