import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './NumberInput.scss';

function NumberInput({
  loading = false,
  inputRef = null,
  className = '',
  labelClassName = '',
  inputClassName = '',
  descriptionClassName = '',
  tabIndex = 0,
  isValid = true,
  disabled = false,
  name = '',
  label = '',
  left = null,
  right = null,
  value = null,
  error = '',
  step = 1,
  min = null,
  max = null,
  description = '',
  onChange = () => {},
  // Why isn't the onChange firing when I type letters?
  // https://github.com/facebook/react/issues/16554
  onBlur = () => {},
}) {
  if (loading) {
    return (
      <div className={`NumberInput ${className}`}>
        {label ? <LoadingSkeleton className="NumberInput-labelText--loader" /> : null}
        <LoadingSkeleton className="NumberInput-input--loader" />
        {description ? <LoadingSkeleton className="NumberInput-description--loader" /> : null}
      </div>
    );
  }

  return (
    <div className={`NumberInput ${disabled ? 'NumberInput--disabled' : ''} ${className}`}>
      {label && (
        <label className="NumberInput-label">
          <div className={`NumberInput-labelText ${!isValid ? 'NumberInput-labelText--error' : ''} ${labelClassName}`}>
            {label}
          </div>
        </label>
      )}
      <div className="NumberInput-inputContainer">
        {left}
        <input
          ref={inputRef}
          type="number"
          className={`NumberInput-input ${!isValid ? 'NumberInput-input--error' : ''} ${inputClassName}`}
          tabIndex={tabIndex}
          disabled={disabled}
          name={name}
          value={value}
          step={step}
          min={min}
          max={max}
          onChange={(e) => {
            onChange(e);
          }}
          onBlur={onBlur}
        />
        {right}
      </div>
      {description && <div className={`NumberInput-description ${descriptionClassName}`}>{description}</div>}
      {!isValid && error && <div className="NumberInput-error">{error}</div>}
    </div>
  );
}

NumberInput.propTypes = {
  loading: PropTypes.bool,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  description: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default NumberInput;
