import PropTypes from 'prop-types';
import { Fzf } from 'fzf';
import React, { useEffect, useRef } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import './ComboboxMulti.scss';

function ComboboxMulti({
  disabled = false,
  description = '',
  error = '',
  isValid = true,
  items = [],
  value = [],
  label = '',
  loading = false,
  emptyPlaceholder = '',
  tabIndex = 0,
  // classes
  className = '',
  buttonClassName = '',
  descriptionClassName = '',
  errorClassName = '',
  inputClassName = '',
  inputRowClassName = '',
  labelClassName = '',
  listClassName = '',
  listItemClassName = '',
  textSummaryStyle = 'count',
  itemNameSingular = 'Item',
  itemNamePlural = 'Items',
  allowSelectAll = false,
  // handlers
  onBlur = () => {},
  onChange: onChangeExt = () => {},
}) {
  const inputRef = useRef(null);
  const [searchText, setInputValue] = React.useState('');
  const [selectedIds, setSelectedIds] = React.useState(value);
  // timed and uses 0-1ms so not worth memoizing right now
  const fzf = new Fzf(items, {
    selector: (item) => item.name || '',
  });
  const searchFoundItems = fzf.find(searchText).map((entry) => entry.item);

  //Pre load image file.
  useEffect(() => {
    const img = new Image();
    img.src = '/src/atoms/Checkbox/checkbox.svg';
  }, []);

  useEffect(() => {
    setSelectedIds(value);
  }, [value.join()]);

  const selectedItems = items.filter((item) => selectedIds.includes(item.id || item.value));

  function onChange(ids) {
    const selectedItems = items.filter((item) => ids.includes(item.id || item.value));
    onChangeExt(selectedItems);
  }

  const { getDropdownProps } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) setSelectedIds(newSelectedItems.id || newSelectedItems.value);
          break;
        default:
          break;
      }
    },
  });
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    openMenu,
  } = useCombobox({
    items: searchFoundItems,
    inputValue: searchText,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem && { isOpen: true }),
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          setSelectedIds((prevSelectedIds) => {
            if (newSelectedItem) {
              if (prevSelectedIds.includes(newSelectedItem?.id || newSelectedItem?.value)) {
                // Remove the item if it's already selected
                const newItemList = prevSelectedIds.filter(
                  (id) => id !== (newSelectedItem.id || newSelectedItem.value),
                );
                onChange(newItemList);
                return newItemList;
              } else {
                // Add the item if it's not selected
                const newItemList = [...prevSelectedIds, newSelectedItem?.id || newSelectedItem?.value];
                onChange(newItemList);
                return newItemList;
              }
            }
            return prevSelectedIds;
          });

          break;
        case useCombobox.stateChangeTypes.InputChange:
          if (newInputValue == ' ') setInputValue('');
          else setInputValue(newInputValue);
          break;
        default:
          break;
      }
    },
  });

  return (
    <div className={`ComboboxMulti ${className}  ${disabled ? 'ComboboxMulti--disabled' : ''}`}>
      <div className={`Comboxbox-field`}>
        {label && (
          <label
            className={`ComboboxMulti-label ${!isValid ? 'ComboboxMulti-label--error' : ''} ${labelClassName}`}
            {...getLabelProps()}
          >
            {label}
          </label>
        )}
        <div
          className={`ComboboxMulti-inputRow ${!isValid ? 'ComboboxMulti-inputRow--error' : ''} ${inputRowClassName}`}
          disabled={disabled}
          onClick={() => {
            inputRef?.current?.focus();
            openMenu();
          }}
        >
          <input
            disabled={loading || disabled}
            placeholder={
              selectedItems.length == 0
                ? emptyPlaceholder
                : textSummaryStyle === 'count'
                ? `${selectedItems.length} ${selectedItems.length > 1 ? itemNamePlural : itemNameSingular} Selected`
                : selectedItems.map((i) => i.name).join(', ')
            }
            className={`ComboboxMulti-input ${!isValid ? 'ComboboxMulti-input--error' : ''} ${inputClassName} `}
            onFocus={() => {
              setInputValue('');
            }}
            onBlur={(e) => {
              closeMenu();
              setInputValue('');
              onBlur(e);
            }}
            tabIndex={tabIndex}
            ref={inputRef}
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
          />
          <button
            className={`ComboboxMulti-rightButton ${buttonClassName}`}
            disabled={disabled}
            type="button"
            {...getToggleButtonProps()}
            aria-label={'toggle menu'}
          >
            {isOpen ? <>&#8593;</> : <>&#8595;</>}
          </button>
        </div>
      </div>
      {description && !isOpen && (
        <div className={`ComboboxMulti-description ${descriptionClassName}`}>{description}</div>
      )}
      {!isValid && !isOpen && <div className={`ComboboxMulti-error ${errorClassName}`}>{error}</div>}
      <ul
        className={`ComboboxMulti-list ${
          isOpen ? 'ComboboxMulti-list--open' : 'ComboboxMulti-list--closed'
        } ${listClassName}`}
        {...getMenuProps()}
      >
        {isOpen && (
          <>
            {allowSelectAll && (
              <div
                className="ComboboxMulti-listItem"
                onMouseDown={(e) => {
                  e.preventDefault();
                  if (selectedItems.length != items.length) {
                    setSelectedIds(items.map((item) => item.id || item.value));
                    onChange(items.map((item) => item.id || item.value));
                  } else {
                    setSelectedIds([]);
                    onChange([]);
                  }
                }}
              >
                <div
                  className={`ComboboxMulti-listItem--checkbox ${
                    selectedItems.length == items.length ? 'checked' : ''
                  }`}
                ></div>
                <span className={`ComboboxMulti-listItemText`}>Select All</span>
              </div>
            )}
            {searchFoundItems.map((item, index) => (
              <li
                className={`
                ComboboxMulti-listItem 
                ${
                  selectedItems.map((i) => i.id || i.value).includes(item.id || item.value)
                    ? 'ComboboxMulti-listItem--selected'
                    : ''
                }
                ${highlightedIndex == index ? 'ComboboxMulti-listItem--highlighted' : ''}
                ${listItemClassName}
              `}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                <div
                  className={`ComboboxMulti-listItem--checkbox ${
                    selectedItems.map((i) => i.id || i.value).includes(item.id || item.value) ? 'checked' : ''
                  }`}
                ></div>
                <span className={`ComboboxMulti-listItemText`}>{item.name}</span>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
}

ComboboxMulti.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  disabled: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  error: PropTypes.string,
  errorClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputRowClassName: PropTypes.string,
  isValid: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      //  expect either id or value as the identifier
      // value is used to make it more compatible with Select/Option setups
      id: PropTypes.string,
      value: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  listClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  emptyPlaceholder: PropTypes.string,
  preSelectedItem: PropTypes.any,
  tabIndex: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.string),
  itemNameSingular: PropTypes.string,
  itemNamePlural: PropTypes.string,
  allowSelectAll: PropTypes.bool,
  textSummaryStyle: PropTypes.string,
};

export default ComboboxMulti;
