import { useFormikContext, Formik } from 'formik';
import './EditFeederModal.scss';
import TextInputField from '../../atoms/TextInput/TextInputField';
import { convertGramsToSmallUnits, convertSmallUnitsToGrams, weightSmallUnitLabel } from '../../utils/unitConversion';
import useUser from '../../utils/hooks/useUser';
import FeedFloButton from '../../atoms/FeedFloButton';
import PropTypes from 'prop-types';
import { feederCheckSchema } from './validation';

function EditFeederModal({ feedLineName, feedLineIndex, feederIndex, onClose }) {
  const { values, setFieldValue } = useFormikContext();
  const currentFeeder = values.feedLines[feedLineIndex].feeders[feederIndex];

  const { user, loading } = useUser();

  if (loading) return <></>;
  return (
    <div className="EditFeederModal">
      <div className="EditFeederModal-content">
        <h1>Edit Feeders for: {feedLineName}</h1>
        <Formik
          initialValues={{
            quantity: currentFeeder.quantity,
            valuesInSmallUnits: currentFeeder.capacity_in_grams
              ? convertGramsToSmallUnits(user.isMetric, currentFeeder.capacity_in_grams, 0)
              : '',
          }}
          validationSchema={feederCheckSchema}
          onSubmit={(values) => {
            setFieldValue(
              `feedLines[${feedLineIndex}].feeders[${feederIndex}].capacity_in_grams`,
              convertSmallUnitsToGrams(user.isMetric, values.valuesInSmallUnits),
            );
            setFieldValue(`feedLines[${feedLineIndex}].feeders[${feederIndex}].quantity`, values.quantity);
            onClose();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <TextInputField inputClassName="FeederEditorDialog-quantityInput" label="Quantity" name={`quantity`} />
              <TextInputField
                inputClassName="FeederEditorDialog-quantityInput"
                label={`Capacity (${weightSmallUnitLabel(user.isMetric)})`}
                name={`valuesInSmallUnits`}
              />

              <div style={{ display: 'flex', 'justify-content': 'space-between', 'margin-top': '2rem' }}>
                <FeedFloButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  Reset
                </FeedFloButton>
                <FeedFloButton
                  onClick={() => {
                    handleSubmit();
                  }}
                  type="success"
                >
                  Continue
                </FeedFloButton>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}
EditFeederModal.propTypes = {
  feedLineName: PropTypes.string,
  feedLineIndex: PropTypes.number,
  feederIndex: PropTypes.number,
  onClose: PropTypes.func,
};

export default EditFeederModal;
