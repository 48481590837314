/* eslint-disable default-case */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useDefaultDateRange from '../../utils/hooks/useDefaultDateRange';
import useSearchParamsState, { useMultipleSearchParamsState } from '../../utils/hooks/useSearchParamsState';
import dayjs from 'dayjs';

import { gql, useQuery } from '@apollo/client';
import Page from '../../atoms/Page';
import BinCard from '../../organisms/BinCard';
import FeedFloDropDown from '../../atoms/FeedFloDropDown/FeedFloDropDown';
import FeedFloDateRangeInput from '../../organisms/FeedFloDateRangeInput/FeedFloDateRangeInput';

const BIN_GQL = gql`
  query BinQuery($barnId: uuid!) {
    bin(where: { farm_id: { _eq: $barnId } }, order_by: { name: asc }) {
      id
    }
    bin_set(
      where: { farm_id: { _eq: $barnId } }
      order_by: [{ bins_aggregate: { min: { name: asc } } }, { created_at: desc }]
    ) {
      id
      bins {
        id
      }
    }
  }
`;

function BinTab({ titleSegments = [], barnId = null }) {
  const [viewMode, setViewMode] = useSearchParamsState('view', 'bins');
  const setMultipleSearchParams = useMultipleSearchParamsState();

  const defaultDateRange = useDefaultDateRange(barnId);
  const [startTime] = useSearchParamsState('start', defaultDateRange.from.unix());
  const [endTime] = useSearchParamsState('end', defaultDateRange.to.unix());
  const [focusBinSet] = useSearchParamsState('focusBinSet', null);
  const [focusStart] = useSearchParamsState('focusStart', null);
  const [focusEnd] = useSearchParamsState('focusEnd', null);

  const startDateTime = dayjs(startTime * 1000);
  const endDateTime = dayjs(endTime * 1000);

  const pageTitleSegments = useMemo(() => ['Bins', ...titleSegments], []);

  const { loading, error, data } = useQuery(BIN_GQL, {
    variables: { barnId },
  });

  if (!barnId) return 'No ID?';
  if (loading) return <span>Loading...</span>;
  if (error) return <span>Bin Tab Error: {JSON.stringify(error)}</span>;

  let dataSets = [];
  switch (viewMode) {
    case 'bins':
      dataSets = data?.bin.map((bin) => [bin.id]);
      break;
    case 'sets':
      dataSets = data?.bin_set.reduce((arr, set) => {
        if (!focusBinSet || set.id == focusBinSet) {
          arr.push(set.bins.map((bin) => bin.id));
        }
        return arr;
      }, []);
      break;
  }

  const cards = dataSets.map((binIds) => (
    <div key={binIds.reduce((key, id) => (key += id), '')} className="flex-card">
      <BinCard
        binIds={binIds}
        to={endDateTime?.unix()}
        from={startDateTime?.unix()}
        toFocus={focusEnd}
        fromFocus={focusStart}
      />
    </div>
  ));

  return (
    <Page className="BinTab" titleSegments={pageTitleSegments}>
      <div className="controlBar">
        <div className="controls">
          <FeedFloDropDown
            list={[
              {
                id: 'bins',
                name: 'Individual Bins',
                selected: 'bins' === viewMode,
              },
              {
                id: 'sets',
                name: 'Grouped by Bin Set',
                selected: 'sets' === viewMode,
              },
            ]}
            label="Display Mode"
            onChange={(item) => {
              setViewMode(item.id);
            }}
          />
        </div>
        <div className="controls">
          <div>
            <label>Date Range</label>
            <FeedFloDateRangeInput
              onChange={(e) => {
                setMultipleSearchParams({ start: e.dateRange.from.unix(), end: e.dateRange.to.unix() });
              }}
              max={dayjs.tz()}
              from={startDateTime}
              to={endDateTime}
            />
          </div>
        </div>
      </div>
      <div className="BinCardHolder">{cards}</div>
    </Page>
  );
}

BinTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default BinTab;
