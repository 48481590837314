import { gql } from '@apollo/client';

export const ANIMAL_GROUP_HEADER_QUERY = gql`
  query AnimalGroupHeader_Query($barnId: uuid!) {
    animal_group(
      where: { deleted_at: { _is_null: true }, barn_id: { _eq: $barnId } }
      order_by: [{ ended_at: desc_nulls_first }, { external_id: asc }]
    ) {
      id
      external_id
      approximate_birthdate
      ended_at
      expected_ended_at
      started_at
      peak_inventory
      latest_inventory
    }
  }
`;
