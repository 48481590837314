import PropTypes from 'prop-types';

import './AnimalGroupPage.scss';
import { gql, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import FeedFloButton from '../../atoms/FeedFloButton';
import AnimalGroupCard from './AnimalGroupCard';
import { useMemo, useState } from 'react';
import Page from 'react-cmdk/dist/components/Page';
import FeedFloErrorBoundary from '../../organisms/FeedFloErrorBoundary';

const GET_ANIMAL_GROUPS_GQL = gql`
  query GetAnimalGroups($barnId: uuid) {
    __typename # Placeholder value
    animal_group(
      where: { barn_id: { _eq: $barnId }, deleted_at: { _is_null: true } }
      order_by: [{ started_at: desc }, { external_id: asc }]
    ) {
      id
      external_id
    }
  }
`;

function AnimalGroupPage({ titleSegments = [], barnId = '', orgId = '' }) {
  const { data, error } = useQuery(GET_ANIMAL_GROUPS_GQL, { variables: { barnId }, skip: !barnId });
  const [newGroupIds, setNewGroupIds] = useState([]);
  const pageTitleSegments = useMemo(() => ['Animals', ...titleSegments], []);

  let groupList = data?.animal_group ? [...data.animal_group] : []; //Expand is used to add new elements to a non editable list

  const newGroups = newGroupIds.map((id) => {
    return { id };
  });
  groupList = newGroups.concat(groupList);

  if (error) throw error;
  return (
    <Page className="AnimalGroupPage" titleSegments={pageTitleSegments}>
      <div className="AnimalGroupPage">
        <div className="AnimalGroupPage-Header">
          <h1>Animal Groups</h1>
          <FeedFloButton
            onClick={() => {
              setNewGroupIds(newGroupIds.concat(uuidv4()));
            }}
          >
            + New Group
          </FeedFloButton>
        </div>

        <div className="AnimalGroupPage-Grid">
          {groupList?.map((g) => {
            return (
              <FeedFloErrorBoundary key={g.id}>
                <AnimalGroupCard
                  key={g.id}
                  group_id={g.id}
                  barnId={barnId}
                  orgId={orgId}
                  onDelete={
                    newGroupIds.some((id) => g.id === id)
                      ? () => {
                          setNewGroupIds(newGroupIds.filter((id) => g.id != id));
                        }
                      : null
                  }
                />
              </FeedFloErrorBoundary>
            );
          })}
        </div>
      </div>
    </Page>
  );
}

AnimalGroupPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
  orgId: PropTypes.string,
};

export default AnimalGroupPage;
