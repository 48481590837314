import PropTypes from 'prop-types';
import SectionalButton from './SectionalButton';
import { useField } from 'formik';

export default function SectionalButtonField({ sections, ...props }) {
  const [field, , helpers] = useField(props);
  const { setValue } = helpers;
  const value = field.value;

  return (
    <SectionalButton
      sections={sections.map((s) => {
        return {
          ...s,
          content: s.text,
          active: s.id === value,
          onClick: () => {
            setValue(s.id);
          },
        };
      })}
    />
  );
}

SectionalButtonField.propTypes = {
  sections: PropTypes.array,
};
