import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '../../atoms/Card';
import StatusBadge from '../../atoms/StatusBadge';
import CollapsibleSection from '../../atoms/CollapsibleSection';
import dayjs from 'dayjs';
import {
  DATE_FORMAT_MONTH_DAY_YEAR,
  DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE,
  TIME_FORMAT_HOURS_MINUTES_SECONDS,
  secondsToDuration,
} from '../../utils/dates';
import WebAppContext from '../../utils/webAppContext';
import {
  convertGramsToSmallUnits,
  weightSmallUnitLabel,
  convertGramsToLargeUnits,
  weightLargeUnitLabel,
} from '../../utils/unitConversion';
import CopyButton from '../../atoms/CopyButton';
import StatusIcon from '../../atoms/StatusIcon';
import FeedFloToolTip from '../../atoms/FeedFloToolTip';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { SearchIcon } from '../../atoms/Icons';
import { Link } from 'react-router-dom';
import ErrorChart from './ErrorChart.jsx';

const MIN_FLOW_RATE = 4535;
const MAX_FLOW_RATE = 22679;

const TestCaseFilterOption = Object.freeze({
  ALL: 'all',
  ACCEPTED: 'accepted',
  DISQUALIFIED: 'disqualified',
});
const TEST_CATEGORIES_GQL = gql`
  query AccuracyScorePage_GetTestCategory(
    $categories: [uuid!]!
    $sha: String!
    $test_case_filter: test_case_bool_exp!
  ) {
    test_category(where: { id: { _in: $categories }, deleted_at: { _is_null: true } }) {
      id
      name
      target_error_type
      target_error_value

      test_cases(
        where: { _and: [$test_case_filter, { deleted_at: { _is_null: true } }] }
        order_by: { started_at: asc }
      ) {
        id
        name
        disqualify_at
        disqualify_reason
        started_at
        ended_at
        target_mass_in_grams
        test_case_feed_frames(
          order_by: { feed_frame: { started_at: asc } }
          where: { deleted_at: { _is_null: true } }
        ) {
          feed_frame {
            started_at
            ended_at
            id
            feed_frame_analyses(where: { algorithm_version: { _eq: $sha } }) {
              latest_estimated_mass_moved_in_grams
            }
            feed_frame_truths(limit: 1, order_by: { created_at: desc }, where: { deleted_at: { _is_null: true } }) {
              truth_mass_in_grams
            }
          }
        }
      }
    }
  }
`;

const FEED_FRAME_LOOKUP_GQL = gql`
  query AccuracyScorePage_FeedFrameLookup($ff_id: uuid!) {
    feed_frame_by_pk(id: $ff_id) {
      feed_line {
        id
        bin_set_id
        farm {
          id
        }
      }
    }
  }
`;

/**
 * Generates the appropriate graphql 'where' field to filter the 'test_cases' relationship.
 * @returns A gql 'where' JSON object.
 */
const generateTestCaseFilter = (testCaseFilter) => {
  if (testCaseFilter === TestCaseFilterOption.ACCEPTED) {
    return { disqualify_at: { _is_null: true } };
  } else if (testCaseFilter === TestCaseFilterOption.DISQUALIFIED) {
    return { disqualify_at: { _is_null: false } };
  } else {
    return {};
  }
};

function TestCategory({ categoryId, sha, testCaseFilter }) {
  const [expanded, setExpanded] = useState(false);
  const { isMetric } = useContext(WebAppContext);

  const { loading, data, error } = useQuery(TEST_CATEGORIES_GQL, {
    variables: {
      categories: categoryId,
      sha,
      test_case_filter: generateTestCaseFilter(testCaseFilter),
    },
  });

  const category = data?.test_category?.[0];

  const testCaseMetaData = useMemo(() => {
    if (!category) {
      return null;
    }
    return category.test_cases.reduce((meta, tc) => {
      const feedFloWeight = tc.test_case_feed_frames.reduce((sum, tc_ff) => {
        return sum + (tc_ff?.feed_frame?.feed_frame_analyses[0]?.latest_estimated_mass_moved_in_grams || 0);
      }, 0);
      const feedFrameDuration = tc.test_case_feed_frames.reduce((sum, tc_ff) => {
        if (!tc_ff?.feed_frame?.ended_at || !tc_ff?.feed_frame?.started_at) return sum;
        return sum + (tc_ff?.feed_frame.ended_at - tc_ff?.feed_frame.started_at);
      }, 0);

      const processedFeedFrames = tc.test_case_feed_frames.reduce((sum, tc_ff) => {
        return sum + (tc_ff?.feed_frame.feed_frame_analyses[0]?.latest_estimated_mass_moved_in_grams >= 0 ? 1 : 0);
      }, 0);

      const score = feedFloWeight / tc.target_mass_in_grams - 1;

      return {
        ...meta,
        [tc.id]: { feedFloWeight, score, feedFrameDuration, processedFeedFrames },
      };
    }, {});
  }, [category]);

  if (loading) {
    return (
      <Card loading={true} accentLocation="left">
        <h4>Loading</h4>
      </Card>
    );
  }
  if (error) {
    return (
      <Card loading={false} accentLocation="left" status="error">
        <h4>Error:</h4>
        <p>{JSON.stringify(error, null, 2)}</p>
      </Card>
    );
  }

  const feedFloMassInGrams = category.test_cases.reduce((sum, tc) => {
    return sum + testCaseMetaData[tc.id]?.feedFloWeight || 0;
  }, 0);

  const targetMassInGrams = category.test_cases.reduce((sum, tc) => {
    return sum + tc?.target_mass_in_grams || 0;
  }, 0);

  const testcaseSuccessCount = category.test_cases.reduce((sum, tc) => {
    const success = Math.abs(testCaseMetaData[tc.id]?.score || 0) * 100 < category.target_error_value;
    return sum + (success ? 1 : 0);
  }, 0);

  const testcaseDoubleErrorMarginSuccessCount = category.test_cases.reduce((sum, tc) => {
    const success = Math.abs(testCaseMetaData[tc.id]?.score || 0) * 100 < category.target_error_value * 2;
    return sum + (success ? 1 : 0);
  }, 0);

  const processedFeedFrames = category.test_cases.reduce((sum, tc) => {
    return sum + testCaseMetaData[tc.id]?.processedFeedFrames || 0;
  }, 0);

  const totalFeedFrameCount = category.test_cases.reduce((sum, tc) => {
    return sum + tc.test_case_feed_frames.length;
  }, 0);

  const categorySuccess = testcaseSuccessCount / category.test_cases.length > 0.95;
  const categoryDoubleSuccess = testcaseDoubleErrorMarginSuccessCount / category.test_cases.length > 0.95;

  const listOfErrors = category.test_cases.map((tc) => {
    return testCaseMetaData[tc.id]?.score;
  });

  return (
    <Card
      key={categoryId}
      loading={false}
      accentLocation="left"
      status={categorySuccess ? 'success' : categoryDoubleSuccess ? 'warning' : 'error'}
    >
      <h4>{category.name}</h4>
      <div className="categoryInfo">
        <div className="categoryStats">
          <p>
            On Target ±{category.target_error_value}%:{' '}
            <StatusBadge
              status={categorySuccess ? 'success' : 'error'}
              text={`${testcaseSuccessCount}/${category.test_cases.length} | ${(
                (testcaseSuccessCount / category.test_cases.length) *
                100
              ).toFixed(0)}%`}
            ></StatusBadge>
          </p>
          <p>
            On Target ±{category.target_error_value * 2}%:{' '}
            <StatusBadge
              status={categoryDoubleSuccess ? 'success' : 'error'}
              text={`${testcaseDoubleErrorMarginSuccessCount}/${category.test_cases.length} | ${(
                (testcaseDoubleErrorMarginSuccessCount / category.test_cases.length) *
                100
              ).toFixed(0)}%`}
            ></StatusBadge>
          </p>
          <p>
            Frames Processed:{' '}
            <StatusBadge
              status={processedFeedFrames / totalFeedFrameCount > 0.99 ? 'success' : 'error'}
              text={`${processedFeedFrames}/${totalFeedFrameCount}`}
            ></StatusBadge>
          </p>
          <p>
            Totals:{' '}
            <StatusBadge
              status={
                Math.abs(feedFloMassInGrams / targetMassInGrams - 1) * 100 < category.target_error_value
                  ? 'success'
                  : 'error'
              }
              text={`${convertGramsToLargeUnits(isMetric, feedFloMassInGrams)} / ${convertGramsToLargeUnits(
                isMetric,
                targetMassInGrams,
              )} ${weightLargeUnitLabel(isMetric)} | ${((feedFloMassInGrams / targetMassInGrams - 1) * 100).toFixed(
                0,
              )}%`}
            ></StatusBadge>
          </p>
          <p>
            <span>
              Test Category ID: {categoryId}
              <FeedFloToolTip description="Copy Category ID">
                <CopyButton data={categoryId} />
              </FeedFloToolTip>
            </span>
          </p>
        </div>
        <div className="categoryChart">
          <ErrorChart height={100} width={400} errors={listOfErrors} />
        </div>
      </div>

      <CollapsibleSection
        isExpanded={expanded}
        onExpandCollapse={() => {
          setExpanded(!expanded);
        }}
        style={{
          width: '100%',
        }}
        title="Test Cases"
      >
        <table className="testCases">
          <thead>
            <tr>
              <th>Score</th>
              <th>Time</th>
              <th>FeedFlo </th>
              <th>Target</th>
              <th>FeedFlo </th>
              <th>Target</th>
              <th>Name</th>
              <th>(TRH|PRC|TOT)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {category.test_cases.map((testCase) => (
              <TestCase
                key={testCase.id}
                testCase={testCase}
                testCaseMetaData={testCaseMetaData[testCase.id]}
                target_error_value={category.target_error_value}
              ></TestCase>
            ))}
          </tbody>
        </table>
      </CollapsibleSection>
    </Card>
  );
}

TestCategory.propTypes = {
  categoryId: PropTypes.string,
  sha: PropTypes.string,
  testCaseFilter: PropTypes.string,
};

function TestCase({ testCase, testCaseMetaData, target_error_value }) {
  const [open, setOpen] = useState(false);
  const [feedFrameLookup] = useLazyQuery(FEED_FRAME_LOOKUP_GQL);

  if (!testCase || !testCaseMetaData) {
    return null;
  }

  const feedFloWeight = testCaseMetaData.feedFloWeight;
  const feedFrameDuration = testCaseMetaData.feedFrameDuration;
  const truthWeight = testCase.target_mass_in_grams;
  const score = (feedFloWeight / truthWeight - 1) * 100;
  let scoreStatus = 'success';
  if (target_error_value < Math.abs(score)) scoreStatus = 'investigate';
  if (target_error_value * 2 < Math.abs(score)) scoreStatus = 'error';

  const feedFloRate = feedFloWeight / (feedFrameDuration / 60.0);
  const trueRate = truthWeight / (feedFrameDuration / 60.0);

  const numTotalFrames = testCase.test_case_feed_frames.length;
  const numTruthFrames = testCase.test_case_feed_frames.filter((ff) => ff?.feed_frame?.feed_frame_truths.length).length;
  const numProccessedFrames = testCaseMetaData.processedFeedFrames;

  const { isMetric } = useContext(WebAppContext);

  const toolTipText = `Flow Rate Range ${convertGramsToSmallUnits(
    isMetric,
    MIN_FLOW_RATE,
    1,
  )}-${convertGramsToSmallUnits(isMetric, MAX_FLOW_RATE, 1)} ${weightSmallUnitLabel(isMetric)}/Min`;
  return (
    <>
      <tr className={'case_details ' + (testCase.disqualify_at != null ? 'disqualified' : '')} key={testCase.id}>
        <td>
          <StatusBadge status={scoreStatus} text={`${score.toFixed(1)}%`}></StatusBadge>
        </td>
        <td>
          {dayjs.tz(testCase.started_at * 1000).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE)}
          <br />
          {dayjs.tz(testCase.ended_at * 1000).format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE)}
        </td>
        <td>
          {convertGramsToLargeUnits(isMetric, feedFloWeight, 3)} {weightLargeUnitLabel(isMetric)}
        </td>

        <td>
          {convertGramsToLargeUnits(isMetric, truthWeight, 3)} {weightLargeUnitLabel(isMetric)}
        </td>
        <td>
          {convertGramsToSmallUnits(isMetric, feedFloRate, 1)} {weightSmallUnitLabel(isMetric)}/Min
          {feedFloRate < MIN_FLOW_RATE || feedFloRate > MAX_FLOW_RATE ? (
            <FeedFloToolTip description={toolTipText}>
              <StatusIcon status="error" />
            </FeedFloToolTip>
          ) : null}
        </td>
        <td>
          {convertGramsToSmallUnits(isMetric, trueRate, 1)} {weightSmallUnitLabel(isMetric)}/Min
          {trueRate < MIN_FLOW_RATE || trueRate > MAX_FLOW_RATE ? (
            <FeedFloToolTip description={toolTipText}>
              <StatusIcon status="error" />
            </FeedFloToolTip>
          ) : null}
        </td>
        <td className="name">{testCase.name}</td>
        <td>
          ({numTruthFrames}|{numProccessedFrames}|{numTotalFrames})
        </td>
        <td>
          <span>
            <FeedFloToolTip description="Copy Test Case ID">
              <CopyButton data={testCase.id} />
            </FeedFloToolTip>
            <FeedFloToolTip description="View FeedFrames">
              <SearchIcon onClick={() => setOpen(!open)} />
            </FeedFloToolTip>
            <FeedFloToolTip description="Copy List of FeedFrames">
              <div
                onClick={async () => {
                  const data = testCase.test_case_feed_frames.map((x) => x.feed_frame.id).join(',');
                  await navigator.clipboard.writeText(data);
                }}
              >
                🎞️
              </div>
            </FeedFloToolTip>
          </span>
        </td>
      </tr>
      {!open || (
        <tr className="case_feed_frames">
          <td colSpan={6}>
            <table className="">
              <thead>
                <tr>
                  <th>Score</th>
                  <th>Time</th>
                  <th>Duration</th>
                  <th>FeedFlo </th>
                  <th>Target</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {testCase.test_case_feed_frames.map((tc_ff) => {
                  const ff = tc_ff.feed_frame;

                  if (typeof ff.feed_frame_truths?.[0]?.truth_mass_in_grams !== 'number') {
                    return null;
                  }

                  const truthWeight = ff.feed_frame_truths[0].truth_mass_in_grams;
                  const feedFloWeight = ff?.feed_frame_analyses?.[0]?.latest_estimated_mass_moved_in_grams || 0;
                  const score = (feedFloWeight / truthWeight - 1) * 100;
                  let scoreStatus = 'success';
                  if (target_error_value < Math.abs(score)) scoreStatus = 'investigate';
                  if (target_error_value * 2 < Math.abs(score)) scoreStatus = 'error';
                  const feedFrameDuration = ff.ended_at - ff.started_at;
                  const feedFloRate = feedFloWeight / (feedFrameDuration / 60.0);
                  const trueRate = truthWeight / (feedFrameDuration / 60.0);

                  return (
                    <tr key={ff.id}>
                      <td>
                        <StatusBadge status={scoreStatus} text={`${score.toFixed(1)}%`}></StatusBadge>
                      </td>
                      <td>
                        {dayjs
                          .tz(ff.started_at * 1000)
                          .format(`${DATE_FORMAT_MONTH_DAY_YEAR} ${TIME_FORMAT_HOURS_MINUTES_SECONDS}`)}
                        <br />
                        {dayjs
                          .tz(ff.ended_at * 1000)
                          .format(`${DATE_FORMAT_MONTH_DAY_YEAR} ${TIME_FORMAT_HOURS_MINUTES_SECONDS}`)}
                      </td>
                      <td>{secondsToDuration(ff.ended_at - ff.started_at)}</td>
                      <td>
                        {convertGramsToSmallUnits(isMetric, feedFloWeight, 1)} {weightSmallUnitLabel(isMetric)}
                        <br />
                        {convertGramsToSmallUnits(isMetric, feedFloRate, 1)} {weightSmallUnitLabel(isMetric)}/Min
                        {feedFloRate < MIN_FLOW_RATE || feedFloRate > MAX_FLOW_RATE ? (
                          <FeedFloToolTip description={toolTipText}>
                            <StatusIcon status="error" />
                          </FeedFloToolTip>
                        ) : null}
                      </td>
                      <td>
                        {convertGramsToSmallUnits(isMetric, truthWeight, 1)} {weightSmallUnitLabel(isMetric)}
                        <br />
                        {convertGramsToSmallUnits(isMetric, trueRate, 1)} {weightSmallUnitLabel(isMetric)}/Min
                        {trueRate < MIN_FLOW_RATE || trueRate > MAX_FLOW_RATE ? (
                          <FeedFloToolTip description={toolTipText}>
                            <StatusIcon status="error" />
                          </FeedFloToolTip>
                        ) : null}
                      </td>
                      <td>
                        <span>
                          <FeedFloToolTip description="Copy Feed Frame ID">
                            <CopyButton data={ff.id} />
                          </FeedFloToolTip>
                          <FeedFloToolTip description="View FeedFrame Page">
                            <Link to={`/ff/${ff.id}`} target="_blank">
                              <SearchIcon />
                            </Link>
                          </FeedFloToolTip>
                          <span
                            className="chartLink"
                            onClick={() => {
                              feedFrameLookup({
                                variables: { ff_id: ff.id },
                                onCompleted: (data) => {
                                  window.open(
                                    `/b/${data.feed_frame_by_pk.feed_line.farm.id}/bins?start=${dayjs(
                                      1000 * ff.started_at,
                                    )
                                      .subtract(1, 'hour')
                                      .unix()}&end=${dayjs(1000 * ff.ended_at)
                                      .add(1, 'hour')
                                      .unix()}&view=sets&focusBinSet=${
                                      data.feed_frame_by_pk.feed_line.bin_set_id
                                    }&focusStart=${ff.started_at}&focusEnd=${ff.ended_at}`,
                                  );
                                  console.log(data.feed_frame_by_pk.feed_line.farm.id);
                                },
                              });
                            }}
                          >
                            📉
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}

TestCase.propTypes = {
  testCase: PropTypes.object,
  testCaseMetaData: PropTypes.object,
  target_error_value: PropTypes.number,
};

export default TestCategory;
