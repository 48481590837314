import { FaultCodes, getFaultName } from '@norimaconsulting/fault-codes';
import useFormattedHourStringFromSeconds from '../useFormattedHourStringFromSeconds';
import { IconEnum, StatusEnum } from '../../enums';

// Hard code these names because they're shortened versions of the actual fault names
const faultCodeToNameMap = {
  [FaultCodes.SUDDEN_CONSUMPTION_DROP]: 'SUDDEN DROP',
  [FaultCodes.CONSUMPTION_TRENDING_DOWN]: 'TRENDING DOWN',
};

export default function useAlertStatusBadges() {
  const { formatHourStringFromSeconds } = useFormattedHourStringFromSeconds();

  const getAlertStatusBadges = ({
    nofeedTimeInSeconds,
    activeFaultCodes = [],
    anomalyFaultCodes = [],
    deviceList = [],
  }) => {
    const badges = [];
    let text = '';
    const hourString = formatHourStringFromSeconds(Math.abs(nofeedTimeInSeconds));
    let noFeedStatus = null;
    if (nofeedTimeInSeconds <= 0) {
      text = `${hourString.toUpperCase()} until NoFeed`;
      noFeedStatus = StatusEnum.Warning;
    } else {
      text = `${hourString.toUpperCase()} of NoFeed`;
      noFeedStatus = StatusEnum.Error;
    }

    activeFaultCodes?.forEach((faultCode) => {
      badges.push({
        icon: IconEnum.Warning,
        text: getFaultName(faultCode || faultCode).toUpperCase(),
        status: noFeedStatus,
      });
    });

    if (deviceList.some((d) => !d.isOnline)) {
      badges.push({
        icon: IconEnum.Warning,
        text: 'Sensor Offline',
        status: StatusEnum.Warning,
      });
    }

    if (activeFaultCodes.length > 0 && nofeedTimeInSeconds != null) {
      badges.push({
        icon: IconEnum.Clock,
        text: text,
        status: noFeedStatus,
      });
    }

    anomalyFaultCodes?.forEach((faultCode) => {
      if (faultCodeToNameMap?.[faultCode]) {
        badges.push({
          icon: IconEnum.EKG,
          text: faultCodeToNameMap[faultCode],
          status: StatusEnum.Investigate,
        });
      }
    });

    if (!badges.length) {
      badges.push({ icon: IconEnum.Logo, text: 'Flowing Fine!', status: StatusEnum.Success });
    }

    return {
      badges,
    };
  };

  return {
    getAlertStatusBadges,
  };
}
