import * as Yup from 'yup';
import { CalibrationStyle } from './enum';

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const calibrationStyleValues = Object.values(CalibrationStyle);
const binSchema = Yup.object().shape({
  id: Yup.string().matches(uuidRegex, 'Bin ID must be a valid UUID').required('Bin ID is required'),
  name: Yup.string()
    .min(1, 'Bin name must be at least 1 characters')
    .max(100, 'Bin name must not exceed 100 characters')
    .required('Bin name is required'),
  binCap: Yup.number().min(1, 'Bin capacity must be at least 1 unit').required('Bin capacity is required'),
});

const feederSchema = Yup.object().shape({
  id: Yup.string().matches(uuidRegex, 'Feeder ID must be a valid UUID').required('Feeder ID is required'),
  capacity_in_grams: Yup.number().min(1, 'Capacity must be at least 1').required('Capacity in grams is required'),
  quantity: Yup.number().min(1, 'Quantity must be at least 1').required('Quantity is required'),
  feed_line_id: Yup.string()
    .matches(uuidRegex, 'feed_line_id must be a valid UUID')
    .required('feed_line_id is required'),
});

const feedLineSchema = Yup.object().shape({
  id: Yup.string().matches(uuidRegex, 'Feed line ID must be a valid UUID').required('Feed line ID is required'),
  name: Yup.string().min(1, 'Feed line name must have at least 1 character').required('Feed line name is required'),
  bins: Yup.array()
    .of(Yup.string().matches(uuidRegex, 'Each bin must be a valid UUID').required('Bin ID is required'))
    .min(1, 'At least one bin is required')
    .required('Bins array is required'),
  feeders: Yup.array().of(feederSchema).required('Feeders array is required'),
});

export const barnSettingsCheckSchema = Yup.object().shape({
  barnName: Yup.string().min(1, 'Barn Name Required').required('Barn name is required'),
  calibrationStyle: Yup.string().oneOf(calibrationStyleValues).required('Calibration Option Required'),
  users: Yup.array()
    .of(Yup.string().matches(uuidRegex, 'Each user must be a valid UUID').required('Each user ID is required'))
    .required('Users array is required'),
  bins: Yup.array()
    .of(binSchema) // Each bin must adhere to the `binSchema`
    .min(1, 'At least one bin is required')
    .required('Bins array is required'),
  feedLines: Yup.array()
    .of(feedLineSchema)
    .min(1, 'At least one feed line is required')
    .required('Feed lines array is required'),
});

export const feederCheckSchema = Yup.object().shape({
  valuesInSmallUnits: Yup.number().min(1, 'Capacity must be at least 1').required('Capacity is required'),
  quantity: Yup.number().min(1, 'Quantity must be at least 1').required('Quantity is required'),
});
