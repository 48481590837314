import PropTypes from 'prop-types';
import NumberInput from './NumberInput';
import { useField, useFormikContext } from 'formik';

export default function NumberInputField({ label, ...props }) {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();

  return (
    <NumberInput
      isValid={(!meta.touched && !submitCount) || !meta.error}
      error={meta.error}
      label={label}
      {...props}
      {...field}
    />
  );
}

NumberInputField.propTypes = {
  label: PropTypes.string,
};
