import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { VariantEnum, ColorEnum } from './enums';
import './Button.scss';

const variantValues = Object.values(VariantEnum);
const variantSet = new Set(variantValues);

const colorValues = Object.values(ColorEnum);
const colorSet = new Set(colorValues);

/**
 * A basic button component.
 * Can take in text, optional left or right JSX sub-components for things like icons,
 * and an onClick handler.
 *
 * @component
 */
function Button({
  loading = false,
  type = 'button',
  className = '',
  style = {},
  tabIndex = 0,
  disabled = false,
  linkTo = '',
  variant = '',
  color = '',
  content = '',
  title = '',
  left = null,
  right = null,
  onClick = () => null,
}) {
  const getVariantColorClassName = () => {
    if (!variantSet.has(variant) || !colorSet.has(color)) {
      if ((variant && !color) || (!variant && color)) {
        console.warn('You must provide both a variant and color prop to a Button to use a non-default style');
      }

      return 'Button--default';
    }

    // Return a string in the form of: 'Button--variant Button--variantColor'
    return `Button--${variant} Button--${variant}${color[0].toUpperCase()}${color.slice(1)}`;
  };

  const leftContent = typeof left === 'string' ? <span>{left}</span> : left;
  const centerContent = typeof content === 'string' ? <span>{content}</span> : content;
  const rightContent = typeof right === 'string' ? <span>{right}</span> : right;

  const button = (
    <button
      type={type}
      className={`Button ${getVariantColorClassName()} ${loading ? 'Button--loading' : ''} ${className}`}
      style={style}
      disabled={disabled || loading}
      tabIndex={tabIndex}
      onClick={onClick}
      title={title}
    >
      {!loading && (
        <>
          {leftContent}
          {centerContent}
          {rightContent}
        </>
      )}
    </button>
  );

  return linkTo ? (
    <Link className="Button--link" to={linkTo}>
      {button}
    </Link>
  ) : (
    button
  );
}

Button.propTypes = {
  /**
   * Determines whether or not to display a loading spinner instead of left / right / content.
   */
  loading: PropTypes.bool,
  /**
   * The HTML attribute for the button tag.
   */
  type: PropTypes.string,
  /**
   * CSS class override.
   */
  className: PropTypes.string,
  /**
   * Style prop override.
   */
  style: PropTypes.object,
  /**
   * Tab index override.
   */
  tabIndex: PropTypes.number,
  /**
   * Disables interaction and applies a default disabled CSS class.
   */
  disabled: PropTypes.bool,
  /**
   * The location to send the user to when clicking the button, if set.
   */
  linkTo: PropTypes.string,
  /**
   * Controls the appearance of the button.
   */
  variant: PropTypes.oneOf(variantValues),
  /**
   * Determines the colour scheme of the button.
   */
  color: PropTypes.oneOf(colorValues),
  /**
   * Content displayed on the button.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Content displayed on a tooltip.
   */
  title: PropTypes.string,
  /**
   * JSX component displayed to the left of the text.
   */
  left: PropTypes.element,
  /**
   * JSX component displayed to the right of the text.
   */
  right: PropTypes.element,
  /**
   * onClick callback.
   */
  onClick: PropTypes.func,
};

export default Button;
