import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import Page from '../../atoms/Page';
import useUser from '../../utils/hooks/useUser';
import TabSection from '../../molecules/TabSection';
import UnsavedChangesBar from '../../molecules/UnsavedChangesBar';
import './BarnSettingsTabV2.scss';
import { CalibrationStyle, CalibrationStyleToString } from './enum';
import { FieldArray, Form, Formik } from 'formik';
import TextInputField from '../../atoms/TextInput/TextInputField';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import { ComboboxMultiField } from '../../atoms/ComboboxMulti';
import NumberInputField from '../../atoms/NumberInput/NumberInputField';
import { toast } from 'react-toastify';

import {
  convertGramsToLargeUnits,
  convertGramsToSmallUnits,
  convertLargeUnitsToGrams,
  weightLargeUnitLabel,
  weightSmallUnitLabel,
} from '../../utils/unitConversion';
import SelectField from '../../atoms/Select/SelectField';
import Combobox from '../../atoms/Combobox/Combobox';
import EditFeederModalTrigger from './EditFeederModalTrigger';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { barnSettingsCheckSchema } from './validation';
import { ToggleSwitchField } from '../../atoms/ToggleSwitch';
import { Beforeunload } from 'react-beforeunload';

const GET_BARN_SETTINGS_GQL = gql`
  query BarnSettingsQuery($barnId: uuid!, $type: String, $version: Int) {
    barn_setting(where: { farm_id: { _eq: $barnId }, type: { _eq: "calibration_settings" }, version: { _eq: 0 } }) {
      settings
    }
    auto_complete_delivery_setting: barn_setting(
      where: { farm_id: { _eq: $barnId }, type: { _eq: "delivery_autocompelete" }, version: { _eq: 1 } }
    ) {
      settings
    }
    barn: farm_by_pk(id: $barnId) {
      id
      organization_id
      external_id
      name
      address
      organization {
        id
      }
      user_barns(where: { deleted_at: { _is_null: true } }) {
        user_id
      }
      bins(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
        id
        name
        bin_set_id
        external_id
        capacity_in_grams
      }
      feed_lines(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
        id
        name
        reference_id
        bin_set_id
        bin_assignment(where: { deleted_at: { _is_null: true } }, order_by: { bin: { name: asc } }) {
          bin_id
        }
        feeders(where: { deleted_at: { _is_null: true } }) {
          id
          feed_line_id
          capacity_in_grams
          quantity
        }
        bin_feed_lines(where: { deleted_at: { _is_null: true } }) {
          bin_id
          feed_line_id
        }
      }
      bin_sets(where: { deleted_at: { _is_null: true } }) {
        id
        feed_lines {
          id
        }
        bins {
          id
        }
      }
      rooms(where: { deleted_at: { _is_null: true } }) {
        id
        name
        reference_id
        room_feed_lines(where: { deleted_at: { _is_null: true } }) {
          feed_line_id
        }
      }
    }
  }
`;

const BARN_SETUP_ONE_GQL = gql`
  mutation UpdateBarn(
    $barn_id: uuid!
    $barn_external_id: String
    $barn_name: String
    $barn_address: String
    $barn_settings: [barn_setting_insert_input!]!
    $upsert_bins: [bin_insert_input!]!
    $delete_bins: [bin_insert_input!]!
    $upsert_feed_lines: [feed_line_insert_input!]!
    $delete_feed_lines: [feed_line_insert_input!]!
    $upsert_bin_feed_lines: [bin_feed_line_insert_input!]!
    $delete_bin_feed_lines: [bin_feed_line_insert_input!]!
    $upsert_bin_sets: [bin_set_insert_input!]!
    $delete_bin_sets: [bin_set_insert_input!]!
    $upsert_feeders: [feeder_insert_input!]!
    $delete_feeders: [feeder_insert_input!]!
    $upsert_rooms: [room_insert_input!]!
    $delete_rooms: [room_insert_input!]!
    $upsert_room_feed_lines: [room_feed_line_insert_input!]!
    $delete_room_feed_lines: [room_feed_line_insert_input!]!
    $upsert_user_barns: [user_barn_insert_input!]!
    $delete_user_barns: [user_barn_insert_input!]!
  ) {
    update_farm_by_pk(
      pk_columns: { id: $barn_id }
      _set: { name: $barn_name, external_id: $barn_external_id, address: $barn_address }
    ) {
      id
    }
    upsert_user_barn: insert_user_barn(
      objects: $upsert_user_barns
      on_conflict: { constraint: user_barn_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    delete_user_barn: insert_user_barn(
      objects: $delete_user_barns
      on_conflict: { constraint: user_barn_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    upsert_bin_set: insert_bin_set(objects: $upsert_bin_sets) {
      affected_rows
    }
    delete_bin_set: insert_bin_set(
      objects: $delete_bin_sets
      on_conflict: { constraint: bin_set_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    upsert_bin: insert_bin(
      objects: $upsert_bins
      on_conflict: { constraint: bin_pkey, update_columns: [name, external_id, capacity_in_grams, bin_set_id] }
    ) {
      affected_rows
    }
    delete_bin: insert_bin(
      objects: $delete_bins
      on_conflict: { constraint: bin_pkey, update_columns: [deleted_at, external_id] }
    ) {
      affected_rows
    }
    upsert_feed_line: insert_feed_line(
      objects: $upsert_feed_lines
      on_conflict: { constraint: feed_line_pkey, update_columns: [name, reference_id, bin_set_id] }
    ) {
      affected_rows
    }
    delete_feed_line: insert_feed_line(
      objects: $delete_feed_lines
      on_conflict: { constraint: feed_line_pkey, update_columns: [deleted_at, reference_id] }
    ) {
      affected_rows
    }
    upsert_bin_feed_line: insert_bin_feed_line(
      objects: $upsert_bin_feed_lines
      on_conflict: { constraint: bin_feed_line_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    delete_bin_feed_line: insert_bin_feed_line(
      objects: $delete_bin_feed_lines
      on_conflict: { constraint: bin_feed_line_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    upsert_feeder: insert_feeder(
      objects: $upsert_feeders
      on_conflict: { constraint: feeder_pkey, update_columns: [capacity_in_grams, quantity] }
    ) {
      affected_rows
    }
    delete_feeder: insert_feeder(
      objects: $delete_feeders
      on_conflict: { constraint: feeder_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    upsert_room: insert_room(
      objects: $upsert_rooms
      on_conflict: { constraint: room_pkey, update_columns: [name, reference_id] }
    ) {
      affected_rows
    }
    delete_room: insert_room(
      objects: $delete_rooms
      on_conflict: { constraint: room_pkey, update_columns: [deleted_at, reference_id] }
    ) {
      affected_rows
    }
    upsert_room_feed_line: insert_room_feed_line(
      objects: $upsert_room_feed_lines
      on_conflict: { constraint: room_feed_line_room_id_feed_line_id_unique, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    delete_room_feed_line: insert_room_feed_line(
      objects: $delete_room_feed_lines
      on_conflict: { constraint: room_feed_line_room_id_feed_line_id_unique, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    insert_barn_setting(
      objects: $barn_settings
      on_conflict: { constraint: barn_setting_type_version_farm_id_unique, update_columns: [settings] }
    ) {
      affected_rows
    }
  }
`;

const GET_USER_LIST_GQL = gql`
  query UserListQuery {
    user(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
      id
      name
      email
    }
  }
`;

const CALIBRATION_SCHEDULES = [
  {
    key: 'none',
    name: 'None',
    bin_check_reminder_enabled: false,
    bin_check_reminder_in_seconds: 0,
    bin_check_overdue_in_seconds: 0,
  },
  {
    key: 'monthly',
    name: 'Monthly',
    bin_check_reminder_enabled: true,
    bin_check_reminder_in_seconds: 2_592_000,
    bin_check_overdue_in_seconds: 604_800,
  },
];

const CALIBRATION_STYLES = [
  {
    id: CalibrationStyle.ExactAndContinuous,
    name: CalibrationStyleToString[CalibrationStyle.ExactAndContinuous],
  },
  {
    id: CalibrationStyle.Continuous,
    name: CalibrationStyleToString[CalibrationStyle.Continuous],
  },
  {
    id: CalibrationStyle.Exact,
    name: CalibrationStyleToString[CalibrationStyle.Exact],
  },
];
const BIN_CHECK_BARN_SETTING = 'calibration_settings';

function buildVariablesFromFormValues(barn_id, values, initialSettingsData, user) {
  const unixNow = dayjs().unix();
  const farm_id = initialSettingsData.barn.id;
  const org_id = initialSettingsData.barn.organization_id;

  const form_bin_sets = [];
  values.feedLines.forEach((fl) => {
    const match = form_bin_sets.find((bs) => {
      return isEqual(bs.bins, new Set(fl.bins));
    });
    if (match) {
      match.feedLines.add(fl.id);
    } else {
      form_bin_sets.push({ bins: new Set(fl.bins), feedLines: new Set([fl.id]) });
    }
  });

  values.bins.forEach((bin) => {
    if (
      !values.feedLines.some((fl) => {
        return fl.bins.includes(bin.id);
      })
    ) {
      form_bin_sets.push({ bins: new Set([bin.id]), feedLines: new Set() });
    }
  });

  values.feedLines.forEach((fl) => {
    if (fl.bins.length === 0) {
      form_bin_sets.push({ bins: new Set(), feedLines: new Set([fl.id]) });
    }
  });

  const db_bin_sets = initialSettingsData?.barn?.bin_sets.map((db_bs) => {
    return {
      id: db_bs.id,
      feedLines: new Set(db_bs.feed_lines.map((fl) => fl.id)),
      bins: new Set(db_bs.bins.map((b) => b.id)),
    };
  });

  const upsert_bin_sets = [];
  const delete_bin_sets = [];
  //Need to delete
  db_bin_sets.forEach((db_bin_set) => {
    const match = form_bin_sets.find((form_bin_set) => {
      return isEqual(form_bin_set.bins, db_bin_set.bins) && isEqual(form_bin_set.feedLines, db_bin_set.feedLines);
    });
    if (!match) {
      delete_bin_sets.push({
        id: db_bin_set.id,
        deleted_at: unixNow,
      });
    }
  });

  //Need to add
  form_bin_sets.forEach((form_bin_set) => {
    const match = db_bin_sets.find((db_bin_set) => {
      return isEqual(form_bin_set.bins, db_bin_set.bins) && isEqual(form_bin_set.feedLines, db_bin_set.feedLines);
    });
    if (!match) {
      const newId = uuidv4();
      upsert_bin_sets.push({
        id: newId,
        farm_id,
      });
      form_bin_set.id = newId;
    } else {
      form_bin_set.id = match.id;
    }
  });

  const originalBinSetMatrix = {};
  initialSettingsData?.barn?.bin_sets.forEach((bin_set) => {
    const originalBinIds = bin_set.bins.map((b) => b.id);
    const originalFeedLineIds = bin_set.feed_lines.map((fl) => fl.id);

    originalBinIds.forEach((bId) => {
      originalFeedLineIds.forEach((flId) => {
        originalBinSetMatrix[`${bId}-${flId}`] = true;
      });
    });
  });

  const delete_bins = [];

  const upsert_bins = values.bins
    .filter((form_bin) => {
      // filter bins to only include the ones that don't match their initial state
      return !initialSettingsData?.barn?.bins.some((initial_bin) => {
        return (
          initial_bin.id === form_bin.id &&
          Math.abs(initial_bin.capacity_in_grams - convertLargeUnitsToGrams(user.isMetric, form_bin.binCap)) < 20_000 &&
          initial_bin.external_id === form_bin.refId &&
          initial_bin.name === form_bin.name &&
          initial_bin.bin_set_id === form_bin_sets.find((bs) => bs.bins.has(initial_bin.id)).id
        );
      });
    })
    .map((b) => {
      return {
        id: b.id,
        name: b.name,
        external_id: b.refId,
        capacity_in_grams: convertLargeUnitsToGrams(user.isMetric, b.binCap),
        bin_set_id: form_bin_sets.find((bs) => bs.bins.has(b.id))?.id || null,
        farm_id,
        org_id,
      };
    });
  initialSettingsData?.barn?.bins.forEach((db_bins) => {
    if (!values.bins.map((b) => b.id).includes(db_bins.id)) {
      delete_bins.push({
        id: db_bins.id,
        deleted_at: unixNow,
        capacity_in_grams: 0,
        farm_id,
        org_id,
        external_id: null,
      });
    }
  });

  let upsert_feeders = [];
  let delete_feeders = [];
  let delete_feed_lines = [];
  const upsert_feed_lines = values.feedLines
    .filter((form_fl) => {
      return !initialSettingsData?.barn?.feed_lines.some((initial_fl) => {
        return (
          initial_fl.id === form_fl.id &&
          initial_fl.name === form_fl.name &&
          initial_fl.reference_id === form_fl.refId &&
          initial_fl.bin_set_id === form_bin_sets.find((bs) => bs.feedLines.has(form_fl.id)).id
        );
      });
    })
    .map((fl) => {
      return {
        id: fl.id,
        name: fl.name,
        reference_id: fl.refId,
        bin_set_id: form_bin_sets.find((bs) => bs.feedLines.has(fl.id)).id,
        farm_id,
        org_id,
      };
    });
  initialSettingsData?.barn?.feed_lines.forEach((db_fl) => {
    if (!values.feedLines.map((fl) => fl.id).includes(db_fl.id)) {
      delete_feed_lines.push({
        id: db_fl.id,
        deleted_at: unixNow,
        farm_id,
        org_id,
        name: db_fl.name,
        reference_id: null,
      });
    }
  });

  values.feedLines.forEach((form_fl) => {
    upsert_feeders = upsert_feeders.concat(
      form_fl?.feeders
        ?.filter((form_feeder) => {
          return !initialSettingsData?.barn?.feed_lines
            .find((initial_fl) => {
              return initial_fl.id === form_fl.id;
            })
            ?.feeders.some((initial_feeder) => {
              return (
                initial_feeder.id === form_feeder.id &&
                initial_feeder.quantity === form_feeder.quantity &&
                initial_feeder.capacity_in_grams === form_feeder.capacity_in_grams
              );
            });
        })
        .filter((f) => !!f)
        .map((feeder) => {
          return {
            capacity_in_grams: feeder.capacity_in_grams,
            id: feeder.id,
            quantity: feeder.quantity,
            feed_line_id: feeder.feed_line_id,
          };
        }) || [],
    );
    initialSettingsData?.barn?.feed_lines
      .find((initial_fl) => {
        return initial_fl.id === form_fl.id;
      })
      ?.feeders.forEach((db_feeder) => {
        if (!form_fl.feeders.map((f) => f.id).includes(db_feeder.id)) {
          delete_feeders.push({
            id: db_feeder.id,
            deleted_at: unixNow,
            feed_line_id: db_feeder.feed_line_id,
            quantity: 0,
            capacity_in_grams: 0,
          });
        }
      });
  });

  const delete_bin_feed_lines = [];
  const upsert_bin_feed_lines = [];

  values.feedLines.forEach((form_fl) => {
    const inital_fl = initialSettingsData?.barn?.feed_lines.find((initial_fl) => {
      return initial_fl.id === form_fl.id;
    });
    inital_fl?.bin_feed_lines?.forEach((initial_b_fl) => {
      if (!form_fl.bins.includes(initial_b_fl.bin_id)) {
        delete_bin_feed_lines.push({
          bin_id: initial_b_fl.bin_id,
          feed_line_id: initial_b_fl.feed_line_id,
          deleted_at: unixNow,
        });
      }
    });
    form_fl.bins.forEach((form_binId) => {
      if (!inital_fl?.bin_feed_lines?.map((b_fl) => b_fl.bin_id).includes(form_binId)) {
        upsert_bin_feed_lines.push({ bin_id: form_binId, feed_line_id: form_fl.id, deleted_at: null });
      }
    });
  });

  const { bin_check_reminder_enabled, bin_check_overdue_in_seconds, bin_check_reminder_in_seconds } =
    values.calibrationStyle != CalibrationStyle.Exact
      ? CALIBRATION_SCHEDULES.find((s) => s.key === values.calibrationSchedule)
      : {};

  const barn_settings = [
    {
      type: BIN_CHECK_BARN_SETTING,
      version: 0,
      farm_id: barn_id,
      settings: {
        calibration_style: values.calibrationStyle,
        bin_check_reminder_enabled,
        bin_check_overdue_in_seconds,
        bin_check_reminder_in_seconds,
      },
    },
    {
      type: 'delivery_autocompelete',
      version: 1,
      farm_id: barn_id,
      settings: {
        active: values.deliveryAutoComplete,
      },
    },
  ];

  const upsert_user_barns = [];
  const delete_user_barns = [];

  values.users.forEach((curr_user_id) => {
    if (!initialSettingsData.barn.user_barns.find((user_barn) => user_barn.user_id === curr_user_id)) {
      upsert_user_barns.push({ barn_id, user_id: curr_user_id, deleted_at: null });
    }
  });

  initialSettingsData.barn.user_barns.forEach((user_barn) => {
    if (!values.users.includes(user_barn.user_id)) {
      delete_user_barns.push({ barn_id, user_id: user_barn.user_id, deleted_at: unixNow });
    }
  });

  const upsert_rooms = [];
  const delete_rooms = [];
  const upsert_room_feed_lines = [];
  const delete_room_feed_lines = [];

  // Look for new Rooms and room Feedlines
  values.rooms.forEach((form_room) => {
    const initial_room = initialSettingsData?.barn.rooms.find((r) => r.id === form_room.id);
    if (initial_room?.name !== form_room.name || initial_room?.reference_id !== form_room.refId) {
      upsert_rooms.push({
        id: form_room.id,
        name: form_room.name,
        reference_id: form_room.refId,
        barn_id: barn_id,
      });
    }
    form_room.feedLines.forEach((fl_id) => {
      if (!initial_room?.room_feed_lines.map((r_fl) => r_fl.id).includes(fl_id)) {
        upsert_room_feed_lines.push({ feed_line_id: fl_id, room_id: form_room.id, deleted_at: null });
      }
    });
  });

  initialSettingsData?.barn.rooms.forEach((initial_room) => {
    const form_room = values.rooms.find((r) => r.id === initial_room.id);
    if (!form_room) {
      delete_rooms.push({ id: initial_room.id, reference_id: null, barn_id: barn_id, deleted_at: unixNow });
    }
    initial_room.room_feed_lines.forEach((r_fl) => {
      if (!form_room?.feedLines.includes(r_fl.feed_line_id)) {
        delete_room_feed_lines.push({ feed_line_id: r_fl.feed_line_id, room_id: initial_room.id, deleted_at: unixNow });
      }
    });
  });

  return {
    barn_id: barn_id,
    barn_external_id: values.barnRefrenceId,
    barn_name: values.barnName,
    barn_address: values.barnAddress,
    barn_settings,
    upsert_bins,
    delete_bins,
    upsert_feed_lines,
    delete_feed_lines,
    upsert_feeders,
    delete_feeders,
    upsert_rooms,
    delete_rooms,
    upsert_room_feed_lines,
    delete_room_feed_lines,
    upsert_bin_feed_lines,
    delete_bin_feed_lines,
    upsert_bin_sets,
    delete_bin_sets,
    upsert_user_barns,
    delete_user_barns,
  };
}

function BarnSettingsTab({ titleSegments = [], barnId = '' }) {
  const { user, isLoading: userIsLoading } = useUser();

  const { loading: settingsLoading, data: initialSettingsData } = useQuery(GET_BARN_SETTINGS_GQL, {
    variables: {
      barnId,
      version: 0,
    },
  });

  const { loading: userListLoading, data: userListData } = useQuery(GET_USER_LIST_GQL);

  const [setupBarnMutation] = useMutation(BARN_SETUP_ONE_GQL, {
    refetchQueries: [GET_BARN_SETTINGS_GQL, 'BarnSummaryQuery'],
    onCompleted: () => {
      toast('Barn Settings Saved', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
      });
    },
    onError: (error) => {
      toast(`Error: ${error.message}`, {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
      });
    },
  });
  const pageTitleSegments = useMemo(() => ['Settings', ...titleSegments], []);
  if (settingsLoading || userIsLoading || userListLoading || typeof user.isMetric != 'boolean') return <></>;

  return (
    <Page className="BarnSettingsTabV2-tabContent" titleSegments={pageTitleSegments}>
      <Formik
        enableReinitialize={true}
        validationSchema={barnSettingsCheckSchema}
        initialValues={{
          barnName: initialSettingsData?.barn?.name,
          barnRefrenceId: initialSettingsData?.barn?.external_id,
          barnAddress: initialSettingsData?.barn?.address,
          calibrationStyle: initialSettingsData?.barn_setting[0]?.settings?.calibration_style || CalibrationStyle.Exact,
          calibrationSchedule: CALIBRATION_SCHEDULES.find(
            (schedule) =>
              schedule.bin_check_reminder_in_seconds ===
              initialSettingsData?.barn_setting[0]?.settings?.bin_check_reminder_in_seconds,
          )?.key,

          users:
            initialSettingsData?.barn?.user_barns.map((user_barn) => {
              return user_barn.user_id;
            }) || [],

          bins: initialSettingsData?.barn?.bins?.map((b) => {
            return {
              id: b.id,
              name: b.name,
              refId: b.external_id,
              binCap: convertGramsToLargeUnits(user?.isMetric, b.capacity_in_grams),
            };
          }),
          feedLines: initialSettingsData?.barn?.feed_lines?.map((fl) => {
            return {
              id: fl.id,
              name: fl.name,
              refId: fl.reference_id,
              bins: fl.bin_assignment.map((bin_assignment) => bin_assignment.bin_id),
              feeders: fl?.feeders?.map((feeder) => {
                return {
                  id: feeder.id,
                  capacity_in_grams: feeder.capacity_in_grams,
                  quantity: feeder.quantity,
                  feed_line_id: fl.id,
                };
              }),
            };
          }),
          rooms: initialSettingsData?.barn?.rooms.map((room) => {
            return {
              id: room.id,
              name: room.name,
              refId: room.reference_id,
              feedLines: room.room_feed_lines?.map((r_fl) => r_fl.feed_line_id) || [],
            };
          }),
          deliveryAutoComplete: initialSettingsData?.auto_complete_delivery_setting[0]?.settings?.active === true,
        }}
        onSubmit={(values) => {
          let variables = null;
          try {
            variables = buildVariablesFromFormValues(barnId, values, initialSettingsData, user);
          } catch (error) {
            toast(`Error: ${error.message}`, {
              type: 'error',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
            });
          }
          if (variables) {
            setupBarnMutation({
              variables,
            });
          }
        }}
      >
        {({ values, handleSubmit, dirty, isValid }) => (
          <Form>
            {dirty && <UnsavedChangesBar isValid={isValid} loading={settingsLoading} onSaveClick={handleSubmit} />}
            {dirty && <Beforeunload onBeforeunload={(event) => event.preventDefault()} />}
            <TabSection heading="Barn Settings" className="settingsSection">
              <div className="BarnSettingsTabV2-barnSettings">
                <TextInputField name="barnName" label={'Barn Name:'} />
                <TextInputField name="barnRefrenceId" label={'Barn Refrence ID:'} />
                <TextInputField name="barnAddress" label={'Barn Address:'} />
              </div>
              <div className="BarnSettingsTabV2-bin-container">
                <FieldArray name="bins">
                  {({ remove, push }) => (
                    <>
                      <div className="BarnSettingsTabV2-bin-container-header">
                        <Button
                          color="success"
                          variant="vivid"
                          content="+"
                          onClick={() => {
                            push({ name: '', id: uuidv4(), binCap: 15, refId: null });
                          }}
                        />
                        <Heading className="TabSection-heading">Bins</Heading>
                      </div>
                      <div className="BarnSettingsTabV2-bin-table">
                        <div className="header">#</div>
                        <div className="header">Name</div>
                        <div className="header">Ref ID</div>
                        <div className="header">Bin Capacity ({weightLargeUnitLabel(user?.isMetric)})</div>
                        <div className="header"></div>
                        {values.bins.length > 0 &&
                          values.bins.map((bin, index) => (
                            <>
                              <div className="cell">{index + 1}</div>
                              <div className="cell">
                                <TextInputField name={`bins.${index}.name`} />
                              </div>
                              <div className="cell">
                                <TextInputField name={`bins.${index}.refId`} />
                              </div>
                              <div className="cell">
                                <NumberInputField name={`bins.${index}.binCap`}></NumberInputField>
                              </div>
                              <div>
                                <Button
                                  content="x"
                                  variant="text"
                                  color="error"
                                  className="remove"
                                  onClick={() => remove(index)}
                                />
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
              <div className="BarnSettingsTabV2-feedline-container">
                <FieldArray name="feedLines">
                  {({ remove, push }) => (
                    <>
                      <div className="BarnSettingsTabV2-feedline-container-header">
                        <Button
                          color="success"
                          variant="vivid"
                          content="+"
                          onClick={() => {
                            const new_fl_id = uuidv4();
                            push({
                              id: new_fl_id,
                              feeders: [
                                { id: uuidv4(), feed_line_id: new_fl_id, quantity: 10, capacity_in_grams: 226796 },
                              ],
                            });
                          }}
                        />
                        <Heading className="TabSection-heading">Feed Lines</Heading>
                      </div>
                      <div className="BarnSettingsTabV2-feedline-table">
                        <div className="header">#</div>
                        <div className="header">Name</div>
                        <div className="header">Ref ID</div>
                        <div className="header">Feeders</div>
                        <div className="header">Bins</div>
                        <div></div>
                        {values.feedLines.length > 0 &&
                          values.feedLines.map((fl, index) => (
                            <>
                              <div className="cell">{index + 1}</div>
                              <div className="cell">
                                <TextInputField name={`feedLines.${index}.name`} />
                              </div>
                              <div className="cell">
                                <TextInputField name={`feedLines.${index}.refId`} />
                              </div>
                              <div className="cell">
                                <FieldArray name={`feedLines.${index}.feeders`}>
                                  {({ remove, push }) => (
                                    <>
                                      {values.feedLines[index]?.feeders?.map((feeder, feederIndex) => (
                                        <div key={`${feederIndex}`} className="FeederEditCell">
                                          <div>
                                            ({feeder?.quantity ? feeder.quantity : '--'}){' '}
                                            {feeder?.capacity_in_grams
                                              ? convertGramsToSmallUnits(user.isMetric, feeder?.capacity_in_grams, 0)
                                              : '--'}
                                            {weightSmallUnitLabel(user.isMetric)}
                                          </div>

                                          <EditFeederModalTrigger
                                            feedLineName={values.feedLines[index].name}
                                            feedLineIndex={index}
                                            feederIndex={feederIndex}
                                          />
                                          <Button
                                            type="primary"
                                            content="X"
                                            variant="text"
                                            color="error"
                                            onClick={() => {
                                              remove();
                                            }}
                                          />
                                        </div>
                                      ))}
                                      {values.feedLines[index]?.feeders.length === 0 && <div>No Feeders</div>}
                                      <Button
                                        type="primary"
                                        content="+"
                                        variant="text"
                                        color="success"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          push({
                                            id: uuidv4(),
                                            feed_line_id: fl.id,
                                            quantity: 10,
                                            capacity_in_grams: 226796,
                                          });
                                        }}
                                      />
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                              <div className="cell">
                                {}
                                <ComboboxMultiField
                                  name={`feedLines.${index}.bins`}
                                  key={`feedLines.${index}.bins`}
                                  emptyPlaceholder="Select Bins"
                                  items={values?.bins?.map((bin) => {
                                    return { name: bin?.name, id: bin?.id };
                                  })}
                                  loading={false}
                                  itemNameSingular="Barn"
                                  itemNamePlural="Barns"
                                  allowSelectAll={false}
                                  disabled={false}
                                  textSummaryStyle="list"
                                />
                              </div>
                              <div>
                                <Button
                                  content="x"
                                  variant="text"
                                  color="error"
                                  className="remove"
                                  onClick={() => remove(index)}
                                />
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>

              <div className="BarnSettingsTabV2-room-container">
                <FieldArray name="rooms">
                  {({ remove, push }) => (
                    <>
                      <div className="BarnSettingsTabV2-room-container-header">
                        <Button
                          color="success"
                          variant="vivid"
                          content="+"
                          onClick={() => {
                            const id = uuidv4();
                            push({
                              id,
                              name: '',
                              refId: '',
                              feedlines: [],
                            });
                          }}
                        />
                        <Heading className="TabSection-heading">Rooms</Heading>
                      </div>
                      <div className="BarnSettingsTabV2-room-table">
                        <div className="header">#</div>
                        <div className="header">Name</div>
                        <div className="header">Ref ID</div>
                        <div className="header">FeedLines</div>
                        <div></div>
                        {values.rooms.length > 0 &&
                          values.rooms.map((fl, index) => (
                            <>
                              <div className="cell">{index + 1}</div>
                              <div className="cell">
                                <TextInputField name={`rooms.${index}.name`} />
                              </div>
                              <div className="cell">
                                <TextInputField name={`rooms.${index}.refId`} />
                              </div>
                              <div className="cell">
                                {}
                                <ComboboxMultiField
                                  name={`rooms.${index}.feedLines`}
                                  key={`rooms.${index}.feedLines`}
                                  emptyPlaceholder="Select FeedLines"
                                  items={values?.feedLines?.map((feedLine) => {
                                    return { name: feedLine?.name, id: feedLine?.id };
                                  })}
                                  loading={false}
                                  allowSelectAll={false}
                                  disabled={false}
                                  textSummaryStyle="list"
                                />
                              </div>
                              <div>
                                <Button
                                  content="x"
                                  variant="text"
                                  color="error"
                                  className="remove"
                                  onClick={() => remove(index)}
                                />
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
              <Heading className="TabSection-heading">Users</Heading>
              <div className="BarnSettingsTabV2-user-container">
                <FieldArray name={`users`}>
                  {({ remove, push }) => (
                    <>
                      <div className="BarnSettingsTabV2-user-table">
                        <div className="header">#</div>
                        <div className="header">Name</div>
                        <div className="header">Email</div>
                        {/* <div className="header">Role</div> */}
                        <div></div>
                        {values.users?.map((user_id, index) => {
                          const user = userListData.user.find((user) => user.id == user_id);
                          return (
                            <>
                              <div className="cell">{index + 1}</div>
                              <div className="cell">{<>{user?.name}</>}</div>
                              <div className="cell">{user.email}</div>
                              {/* <div className="cell">
                              <SelectField
                                name="Role1"
                                defaultText="Select Role"
                                itemList={[
                                  { name: 'Barn Manager', id: 1 },
                                  { name: 'Company Vet', id: 2 },
                                  { name: 'Swine Technician', id: 3 },
                                ]}
                                value={1}
                              ></SelectField>
                            </div> */}
                              <div>
                                <Button
                                  content="x"
                                  variant="text"
                                  color="error"
                                  className="remove"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                />
                              </div>
                            </>
                          );
                        })}
                        <>
                          <div className="cell">{}</div>
                          <div className="cell">
                            <Combobox
                              items={userListData.user.map((user) => {
                                return { id: user.id, name: `${user.name || ''} - ${user.email}` };
                              })}
                              onChange={(u) => {
                                push(u.id);
                              }}
                            ></Combobox>
                          </div>
                          <div className="cell">{}</div>
                          {/* <div className="cell">
                              <SelectField
                                name="Role1"
                                defaultText="Select Role"
                                itemList={[
                                  { name: 'Barn Manager', id: 1 },
                                  { name: 'Company Vet', id: 2 },
                                  { name: 'Swine Technician', id: 3 },
                                ]}
                                value={1}
                              ></SelectField>
                            </div> */}
                          <div></div>
                        </>
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
              <Heading className="TabSection-heading">Calibration Settings</Heading>
              <div className="BarnSettingsTabV2-barnSettings">
                <SelectField
                  label="Calibration Style"
                  name="calibrationStyle"
                  itemList={CALIBRATION_STYLES.map((s) => {
                    return { ...s };
                  })}
                />
                {(values.calibrationStyle === CalibrationStyle.Continuous ||
                  values.calibrationStyle === CalibrationStyle.ExactAndContinuous) && (
                  <SelectField
                    label="Calibration Schedule"
                    name="calibrationSchedule"
                    itemList={CALIBRATION_SCHEDULES.map((s) => {
                      return { id: s.key, name: s.name };
                    })}
                  />
                )}
              </div>
              <Heading className="TabSection-heading">Automation Settings</Heading>
              <div className="BarnSettingsTabV2-barnSettings">
                <div className="BarnSettingsTabV2-automation-row">
                  <div className="BarnSettingsTabV2-automation-row-label">
                    Automatically mark feed orders as delivered after their scheduled time:
                  </div>

                  <ToggleSwitchField
                    className="BarnSettingsTabV2-automation-row-toggle"
                    label="Mark Feed Deliveries as Delivered Automatically"
                    name="deliveryAutoComplete"
                  />
                </div>
              </div>
            </TabSection>
          </Form>
        )}
      </Formik>
    </Page>
  );
}

BarnSettingsTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default BarnSettingsTab;
