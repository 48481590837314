import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useAlertStatusBadges from '../../utils/hooks/useAlertStatusBadges';
import { useCensor, censorTypes } from '../../utils/hooks/useCensor';
import BarnListHeader from './BarnListHeader';
import BarnBlock from '../../organisms/BarnBlock';
import StatusBadge from '../../atoms/StatusBadge';
import { LeftChevronIcon, RightChevronIcon } from '../../atoms/Icons';
import './BarnListSection.scss';

function BarnListSection({
  loading = false,
  heading = '',
  viewMode = 'list',
  selectedRowIndex = -1,
  barnList = [],
  currentPage = 0,
  maxPage = 0,
  emptyTitle = '',
  emptyImage = '',
  emptyText = '',
  onNextPageClick = () => {},
  onPreviousPageClick = () => {},
  onPinBarnClick = () => {},
}) {
  const { getAlertStatusBadges } = useAlertStatusBadges();
  const nextPageButtonDisabled = currentPage >= maxPage;
  const previousPageButtonDisabled = currentPage <= 0;
  const { censor } = useCensor();
  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (!barnList.length) {
      return (
        <div className="BarnListSection-empty">
          <div className="BarnListSection-emptyMessage">
            <h6 className="BarnListSection-emptyTitle">{emptyTitle}</h6>
            <img className="BarnListSection-emptyImage" src={emptyImage} />
            <p className="BarnListSection-emptyText">{emptyText}</p>
          </div>
        </div>
      );
    }

    if (viewMode === 'card') {
      return <div className="BarnListSection-cardItems">{renderBarns()}</div>;
    } else {
      return (
        <div className="BarnListSection-listItems">
          <BarnListHeader />
          {renderBarns()}
        </div>
      );
    }
  };

  const renderBarns = () => {
    const completeBarnList = [...barnList];

    if (viewMode !== 'card') {
      completeBarnList.splice(5, 1);
    }

    return completeBarnList.map((barn, i) => {
      const { badges } = getAlertStatusBadges({
        nofeedTimeInSeconds: barn.nofeed_seconds,
        activeFaultCodes: barn.active_fault_codes,
        anomalyFaultCodes: barn.anomaly_fault_codes,
      });

      // Convert positive nofeed seconds into hours.
      const nofeedTimeSeconds = barn?.seconds_of_nofeed || 0;
      const formattedNoFeedTime = dayjs.duration({ seconds: nofeedTimeSeconds }).asHours().toFixed(0);

      return (
        <BarnBlock
          key={barn.barn_id}
          className={i === selectedRowIndex ? 'BarnBlock--selected' : ''}
          id={barn.barn_id}
          viewMode={viewMode}
          status={barn.status}
          isPinned={barn.is_pinned}
          name={censor(barn.barn_name, censorTypes.barn)}
          orgName={
            barn.showOrgName && barn.organization ? censor(barn.organization.name, censorTypes.organization) : ''
          }
          address={censor(barn.address, censorTypes.pii)}
          statValue={barn.statValue}
          statUnit={barn.statUnit}
          statLabel={barn.statLabel}
          statValue2={barn.statValue2}
          statUnit2={barn.statUnit2}
          statLabel2={barn.statLabel2}
          nofeedTime={formattedNoFeedTime}
          isPreviewItem={i === completeBarnList.length - 1}
          onPinBarnClick={onPinBarnClick}
        >
          {badges.map((badge) => (
            <StatusBadge
              key={badge.text}
              className="BarnBlock-statusBadge"
              status={badge.status}
              icon={badge.icon}
              text={badge.text}
            />
          ))}
        </BarnBlock>
      );
    });
  };

  if (loading) {
    return (
      <div className="BarnListSection">
        <div className="BarnListSection-headingRow">
          <h4 className="BarnListSection-heading">{heading}</h4>
          <div className="BarnListSection-navigationButtons">
            <LeftChevronIcon className="BarnListSection-previousPage BarnListSection-previousPage--disabled" />
            <RightChevronIcon className="BarnListSection-nextPage BarnListSection-nextPage--disabled" />
          </div>
        </div>
        {viewMode === 'card' ? (
          <div className="BarnListSection-cardItemsWrapper">
            <div className="BarnListSection-cardItems">
              <BarnBlock loading={true} viewMode="card" />
              <BarnBlock loading={true} viewMode="card" />
              <BarnBlock loading={true} viewMode="card" />
              <BarnBlock loading={true} viewMode="card" />
              <BarnBlock loading={true} viewMode="card" />
              <BarnBlock loading={true} viewMode="card" />
            </div>
          </div>
        ) : (
          <div className="BarnListSection-listItems">
            <BarnListHeader />
            <BarnBlock loading={true} viewMode="list" />
            <BarnBlock loading={true} viewMode="list" />
            <BarnBlock loading={true} viewMode="list" />
            <BarnBlock loading={true} viewMode="list" />
            <BarnBlock loading={true} viewMode="list" />
            <BarnBlock loading={true} viewMode="list" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="BarnListSection">
      <div className="BarnListSection-headingRow">
        <h4 className="BarnListSection-heading">{heading}</h4>
        <div className="BarnListSection-navigationButtons">
          <LeftChevronIcon
            className={`BarnListSection-previousPage ${
              previousPageButtonDisabled ? 'BarnListSection-previousPage--disabled' : ''
            }`}
            onClick={onPreviousPageClick}
          />
          <RightChevronIcon
            className={`BarnListSection-nextPage ${nextPageButtonDisabled ? 'BarnListSection-nextPage--disabled' : ''}`}
            onClick={onNextPageClick}
          />
        </div>
      </div>
      {renderContent()}
    </div>
  );
}

BarnListSection.propTypes = {
  loading: PropTypes.bool,
  heading: PropTypes.string,
  viewMode: PropTypes.oneOf(['card', 'list']),
  selectedRowIndex: PropTypes.number,
  barnList: PropTypes.array,
  previewItem: PropTypes.object,
  currentPage: PropTypes.number,
  maxPage: PropTypes.number,
  emptyTitle: PropTypes.string,
  emptyImage: PropTypes.string,
  emptyText: PropTypes.string,
  onNextPageClick: PropTypes.func,
  onPreviousPageClick: PropTypes.func,
  onPinBarnClick: PropTypes.func,
};

export default BarnListSection;
