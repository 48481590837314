import React from 'react';
import * as Sentry from '@sentry/react';

import './FeedFloErrorBoundary.scss';
import Card from '../../atoms/Card';
import FeedFloButton from '../../atoms/FeedFloButton';
import { Refresh } from '../../atoms/Icons';
import PropTypes from 'prop-types';

const ErrorFallback = ({ error, componentStack, resetError }) => (
  <Card className="ErrorFallback" role="alert">
    <h2 className="ErrorFallback-Heading">
      Uh-oh! The web page&apos;s feedline is clogged. We&apos;re clearing it out now.
    </h2>
    <p>Our team has been notified and are investigating the problem.</p> <br />
    {error && error.toString()}
    {error && (
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {componentStack}
      </details>
    )}
    <div className="buttonHolder">
      <FeedFloButton onClick={resetError} type="primary" leftIcon={<Refresh />}>
        Try Again
      </FeedFloButton>
      <FeedFloButton
        onClick={() => {
          window.location.reload();
        }}
        type="primary"
        leftIcon={<Refresh />}
      >
        Reload Page
      </FeedFloButton>
    </div>
  </Card>
);

ErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetError: PropTypes.func,
  componentStack: PropTypes.string,
};

const FeedFloErrorBoundary = ({ children, fallback, ...props }) => {
  return (
    <Sentry.ErrorBoundary fallback={fallback || ErrorFallback} {...props}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

FeedFloErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.element,
};

export default FeedFloErrorBoundary;
