import PropTypes from 'prop-types';

import './AnimalGroupPage.scss';
import { gql, useMutation, useQuery } from '@apollo/client';
import Card from '../../atoms/Card';
import { FieldArray, Formik } from 'formik';
import TextInputField from '../../atoms/TextInput/TextInputField';
import DateTimeInputField from '../../atoms/DateTimeInput/DateTimeInputField';
import NumberInputField from '../../atoms/NumberInput/NumberInputField';
import ToggleSwitchField from '../../atoms/ToggleSwitch/ToggleSwitchField';
import SelectField from '../../atoms/Select/SelectField';
import { LEDGER_TRANSACTION_IN_ORDER, LEDGER_TRANSACTION_REASON_NAMES } from './enum';
import Button from '../../atoms/Button';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { animalGroupSchema } from './validation';
import { DATE_FORMAT_DASH } from '../../utils/dates';
import { ComboboxMultiField } from '../../atoms/ComboboxMulti';
import FeedFloErrorBoundary from '../../organisms/FeedFloErrorBoundary';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import UnsavedChangesBar from '../../molecules/UnsavedChangesBar';
import { Beforeunload } from 'react-beforeunload';

const GET_ANIMAL_GROUP_DETAILS_GQL = gql`
  query GetAnimalGroupDetails($group_id: uuid, $barn_id: uuid) {
    animal_group(where: { id: { _eq: $group_id } }) {
      id
      external_id
      started_at
      ended_at
      expected_ended_at
      approximate_birthdate
      animal_group_ledgers(where: { deleted_at: { _is_null: true } }, order_by: { intended_at: asc }) {
        id
        intended_at
        transaction_at
        quantity
        reason
        notes
      }
      animal_group_rooms(where: { deleted_at: { _is_null: true }, room: { deleted_at: { _is_null: true } } }) {
        id
        room_id
      }
    }
    rooms: room(where: { deleted_at: { _is_null: true }, barn_id: { _eq: $barn_id } }) {
      id
      name
    }
  }
`;

const UPDATE_ANIMAL_GROUP = gql`
  mutation UpdateAnimalGroup(
    $animal_group: animal_group_insert_input!
    $ledgers: [animal_group_ledger_insert_input!]!
    $delete_ledgers: [animal_group_ledger_insert_input!]!
    $group_rooms: [animal_group_room_insert_input!]!
  ) {
    insert_animal_group_one(
      object: $animal_group
      on_conflict: {
        constraint: animal_group_pkey
        update_columns: [approximate_birthdate, ended_at, expected_ended_at, external_id, started_at]
      }
    ) {
      id
    }
    insert_animal_group_ledger(
      objects: $ledgers
      on_conflict: {
        constraint: animal_group_ledger_pkey
        update_columns: [intended_at, notes, quantity, reason, transaction_at]
      }
    ) {
      affected_rows
    }
    delete_animal_group_ledger: insert_animal_group_ledger(
      objects: $delete_ledgers
      on_conflict: { constraint: animal_group_ledger_pkey, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
    insert_animal_group_room(
      objects: $group_rooms
      on_conflict: { constraint: animal_group_room_animal_group_id_room_id_unique, update_columns: [deleted_at] }
    ) {
      affected_rows
    }
  }
`;

const SHOW_FIRST = 3;
const SHOW_LAST = 3;

function AnimalGroupCard({ group_id = '', barnId = '', orgId = '', onDelete = null }) {
  const { data, error } = useQuery(GET_ANIMAL_GROUP_DETAILS_GQL, {
    variables: { group_id, barn_id: barnId },
    skip: !barnId || !group_id,
  });
  const unixNow = dayjs().unix();
  const [openTx, setOpenTX] = useState(false);

  if (error) throw error;
  const [updateAnimalGroup, { error: update_group_error }] = useMutation(UPDATE_ANIMAL_GROUP, {
    refetchQueries: [GET_ANIMAL_GROUP_DETAILS_GQL],
    onCompleted: () => {
      toast('Group Data Saved', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
      });
    },
    onError: (error) => {
      toast(`Error: ${error.message}`, {
        type: 'error',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
      });
    },
  });
  if (update_group_error) throw update_group_error;
  const db_group = data?.animal_group?.[0] || {};

  const unixSecondsToInputStr = (t) => {
    return t ? dayjs.tz(t * 1000).format(DATE_FORMAT_DASH) : '';
  };

  const onFormSubmit = (values) => {
    const variables = {
      animal_group: {
        id: group_id,
        organization_id: orgId,
        barn_id: barnId,
        external_id: values.referenceId,
      },
      ledgers: [],
      delete_ledgers: [],
      group_rooms:
        values?.rooms?.map((r) => {
          return { room_id: r, animal_group_id: group_id };
        }) || [],
    };
    if (values.start_date && dayjs.tz(values.start_date).isValid()) {
      variables.animal_group.started_at = dayjs.tz(values.start_date).unix();
    }

    if (dayjs.tz(values.birth_date).isValid()) {
      variables.animal_group.approximate_birthdate = dayjs.tz(values.birth_date).unix();
    }

    if (values.actual_end_date && dayjs.tz(values.actual_end_date).isValid()) {
      variables.animal_group.ended_at = dayjs.tz(values.actual_end_date).endOf('day').unix();
    }

    if (values.estimated_end_date && dayjs.tz(values.estimated_end_date).isValid()) {
      variables.animal_group.expected_ended_at = dayjs.tz(values.estimated_end_date).endOf('day').unix();
    }

    if (values.transactions) {
      variables.ledgers = values.transactions.map((t) => {
        const intended_at = dayjs.tz(t.time).unix();
        const transaction_at = t.cleared ? intended_at : null;
        return {
          id: t.id,
          animal_group_id: group_id,
          intended_at,
          transaction_at,
          quantity: t.quantity,
          reason: t.reason,
          notes: t.notes,
        };
      });
    }

    db_group?.animal_group_ledgers?.forEach((ag_l) => {
      if (!values.transactions.map((t) => t.id).includes(ag_l.id)) {
        variables.delete_ledgers.push({
          id: ag_l.id,
          animal_group_id: group_id,
          deleted_at: unixNow,
        });
      }
    });

    db_group?.animal_group_rooms?.forEach((ag_r) => {
      if (!values.rooms.includes(ag_r.room_id)) {
        variables.group_rooms.push({
          id: ag_r.id,
          room_id: ag_r.room_id,
          animal_group_id: group_id,
          deleted_at: unixNow,
        });
      }
    });
    updateAnimalGroup({ variables });
  };

  return (
    <Card className="AnimalGroupCard">
      <FeedFloErrorBoundary>
        <div>
          <Formik
            initialValues={{
              id: group_id,
              referenceId: db_group.external_id || '',
              start_date: unixSecondsToInputStr(db_group.started_at),
              birth_date: unixSecondsToInputStr(db_group.approximate_birthdate),
              estimated_end_date: unixSecondsToInputStr(db_group.expected_ended_at),
              actual_end_date: unixSecondsToInputStr(db_group.ended_at),
              transactions:
                db_group?.animal_group_ledgers?.map((a_g_l) => {
                  return {
                    id: a_g_l.id,
                    quantity: a_g_l.quantity,
                    notes: a_g_l.notes || '',
                    reason: a_g_l.reason,
                    cleared: !!a_g_l.transaction_at,
                    time: unixSecondsToInputStr(a_g_l.intended_at),
                  };
                }) || [],
              rooms: db_group?.animal_group_rooms?.map((room) => room.room_id),
            }}
            enableReinitialize={true}
            validationSchema={animalGroupSchema}
            onSubmit={onFormSubmit}
          >
            {({ handleSubmit, values, dirty, initialValues }) => {
              const newTransactionCount = values.transactions.length - initialValues.transactions.length;
              return (
                <>
                  <form>
                    {dirty && <UnsavedChangesBar isValid={true} onSaveClick={handleSubmit} />}
                    {dirty && <Beforeunload onBeforeunload={(event) => event.preventDefault()} />}

                    <div className="AnimalGroupCard-GroupInfo">
                      <TextInputField name="referenceId" label={'Group ID *'} />
                      <ComboboxMultiField
                        name="rooms"
                        label={'Rooms *'}
                        textSummaryStyle={'summary'}
                        loading={!data?.rooms}
                        items={
                          data?.rooms?.map((r) => {
                            return { id: r.id, name: r.name };
                          }) || []
                        }
                      />
                      <DateTimeInputField name="start_date" label={'Start Date *'} showTime={false} />
                      <DateTimeInputField name="birth_date" label={'Approx. Birth Date *'} showTime={false} />
                      <DateTimeInputField name="estimated_end_date" label={'Target End Date'} showTime={false} />
                      <DateTimeInputField name="actual_end_date" label={'Actual End Date'} showTime={false} />
                    </div>
                  </form>
                  <hr></hr>
                  <h2>Inventory</h2>
                  <FieldArray name="transactions" key={'FieldArrayTransactions'}>
                    {({ remove, push }) => (
                      <form key={'FieldArray'}>
                        <div key={'AnimalGroupCard-Transactions-grid'} className="AnimalGroupCard-Transactions-grid">
                          <div className="header">Time</div>
                          <div className="header">Reason</div>
                          <div className="header">Qty</div>
                          <div className="header">Note</div>
                          <div className="header">Total</div>
                          <div className="header">Cleared</div>
                          <div className="header"></div>
                          {values.transactions.length > 0 &&
                            values.transactions.map((transaction, index) => (
                              <>
                                {(openTx ||
                                  index < SHOW_FIRST ||
                                  index > values.transactions.length - (SHOW_LAST + 1 + newTransactionCount) ||
                                  values.transactions.length < SHOW_FIRST + SHOW_LAST) && (
                                  <React.Fragment key={transaction.id}>
                                    <DateTimeInputField name={`transactions.${index}.time`} showTime={false} />
                                    <SelectField
                                      name={`transactions.${index}.reason`}
                                      itemList={LEDGER_TRANSACTION_IN_ORDER.map((id) => {
                                        return { id: id, name: LEDGER_TRANSACTION_REASON_NAMES[id] };
                                      })}
                                      defaultText="Select"
                                    />
                                    <NumberInputField name={`transactions.${index}.quantity`} />
                                    <TextInputField
                                      key={`notes ${transaction.id}`}
                                      name={`transactions.${index}.notes`}
                                    />
                                    <div className="AnimalGroupCard-Transactions-grid-total">
                                      {values.transactions.slice(0, index + 1).reduce((sum, t) => {
                                        return (t.quantity || 0) + sum;
                                      }, 0)}
                                    </div>
                                    <ToggleSwitchField name={`transactions.${index}.cleared`} />
                                    <div>
                                      <Button
                                        content="X"
                                        variant="text"
                                        color="error"
                                        className="remove"
                                        onClick={() => remove(index)}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                                {index == SHOW_FIRST &&
                                  !openTx &&
                                  values.transactions.length > SHOW_FIRST + SHOW_LAST + newTransactionCount && (
                                    <div className="openRow">
                                      <hr />
                                      <Button
                                        color="success"
                                        variant="text"
                                        content={`Show ${
                                          values.transactions.length - (SHOW_FIRST + SHOW_LAST + newTransactionCount)
                                        } Hidden Transactions`}
                                        onClick={() => {
                                          setOpenTX(true);
                                        }}
                                      />
                                      <hr />
                                    </div>
                                  )}
                              </>
                            ))}
                        </div>
                        <Button
                          className="AnimalGroupCard-Transactions-button"
                          color="success"
                          variant="pastel"
                          content="+ New Record"
                          onClick={() => {
                            push({ id: uuidv4(), cleared: true, time: dayjs.tz().format(DATE_FORMAT_DASH) });
                          }}
                        />
                      </form>
                    )}
                  </FieldArray>
                </>
              );
            }}
          </Formik>
        </div>
      </FeedFloErrorBoundary>
      {onDelete && (
        <>
          <hr />
          <div className="AnimalGroupCard-delete">
            <Button
              color="error"
              variant="pastel"
              content="Delete Group"
              onClick={() => {
                onDelete();
              }}
            />
          </div>
        </>
      )}
    </Card>
  );
}

AnimalGroupCard.propTypes = {
  group_id: PropTypes.string,
  barnId: PropTypes.string,
  orgId: PropTypes.string,
  onDelete: PropTypes.func,
};

export default AnimalGroupCard;
