import * as Yup from 'yup';
import { LEDGER_TRANSACTION_REASON, LEDGER_TRANSACTION_REASON_NAMES, LEDGER_TRANSACTION_REASON_POLARITY } from './enum';
import dayjs from 'dayjs';

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const ledgerTransactionReasons = Object.values(LEDGER_TRANSACTION_REASON);
const transactionSchema = Yup.object().shape({
  id: Yup.string().matches(uuidRegex, 'Bin ID must be a valid UUID').required('Bin ID is required'),
  reason: Yup.string().oneOf(ledgerTransactionReasons).required('Reason Required'),
  time: Yup.string()
    .required('Required')
    .test('isValidDate', 'Invalid date format', function (value) {
      return dayjs.tz(value).isValid();
    }),
  quantity: Yup.number()
    .required('Required')
    .test('positiveNegitiveCheck', '', (qty, context) => {
      const reason = context.parent.reason;
      const pole = LEDGER_TRANSACTION_REASON_POLARITY[reason];
      const result = !(pole === 1 && qty <= 0) && !(pole === -1 && qty >= 0);
      if (!result) {
        let desiredString = 'Positive';
        if (pole === -1) desiredString = 'Negitive';
        return context.createError({
          message: ` ${LEDGER_TRANSACTION_REASON_NAMES[reason]} must be ${desiredString}`,
          path: context.path, // Fieldname
        });
      }
      return result;
    }),
});

export const animalGroupSchema = Yup.object().shape({
  id: Yup.string().matches(uuidRegex, 'Group ID must be a valid UUID').required('Group ID is required'),
  referenceId: Yup.string().min(1, 'Reference ID Required').required('Reference ID is required'),
  birth_date: Yup.string()
    .required('Birth date is required')
    .test('isValidDate', 'Invalid date format', function (value) {
      return !value || dayjs.tz(value).isValid();
    }),
  start_date: Yup.string()
    .required('Start date is required')
    .test('isValidDate', 'Invalid date format', function (value) {
      return dayjs.tz(value).isValid();
    }),
  estimated_end_date: Yup.string()
    .nullable()
    .test('isValidDate', 'Invalid date format', function (value) {
      return !value || dayjs.tz(value).isValid();
    }),
  actual_end_date: Yup.string()
    .nullable('End Date must be valid')
    .test('isValidDate', 'Invalid date format', function (value) {
      return !value || dayjs.tz(value).isValid();
    }),
  rooms: Yup.array().min(1).required('One or more rooms are required'),
  transactions: Yup.array().of(transactionSchema).required('Transactions are not valid'),
});
