import React, { useEffect, useRef, useState } from 'react';

import './FeedFloDateRangeInput.scss';
import PropTypes from 'prop-types';
import { CalendarIcon } from '../../atoms/Icons';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { DATE_FORMAT_DASH, DATE_FORMAT_MONTH_DAY } from '../../utils/dates';
import FeedFloButton from '../../atoms/FeedFloButton';
import useUser from '../../utils/hooks/useUser';
import './react-datepicker.scss';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

const FeedFloDateRangeInput = ({
  loading = false,
  className = '',
  labelClassName = '',
  disabled = false,
  label = '',
  from = 0,
  to = 31_536_000,
  max = 31_536_000,
  onChange = () => {},
}) => {
  let fromLabel = 'MMM DD';
  let toLabel = 'MMM DD';

  const [show, setShow] = useState(false);

  const [appliedStartDate, setAppliedStartDate] = useState(dayjs.tz(from).startOf('day'));
  const [appliedEndDate, setAppliedEndDate] = useState(dayjs.tz(to).endOf('day'));

  const [startDate, setStartDate] = useState(dayjs.tz(from).startOf('day'));
  const [endDate, setEndDate] = useState(dayjs.tz(to).endOf('day'));
  const maxDate = dayjs.tz(max).endOf('day');
  const { user } = useUser();
  // the string inputed in the text box in the header of date picker
  const [startText, setStartText] = useState('');
  const [endText, setEndText] = useState('');
  const fromPlaceHolder = dayjs(from).format(DATE_FORMAT_DASH);
  const toPlaceHolder = dayjs(to).format(DATE_FORMAT_DASH);

  if (startDate && startDate.isValid()) {
    fromLabel = startDate.format(DATE_FORMAT_MONTH_DAY);
  }
  if (endDate && endDate.isValid()) {
    toLabel = endDate.format(DATE_FORMAT_MONTH_DAY);
  }

  /**
   * For detection if outside is clicked
   */
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShow(false);
        onCancelClick(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    setStartText(dayjs.tz(startDate).format(DATE_FORMAT_DASH));

    if (!endDate) {
      setEndText('');
    } else {
      setEndText(dayjs.tz(endDate).format(DATE_FORMAT_DASH));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(dayjs.tz(from).startOf('day'));
  }, [from]);

  useEffect(() => {
    setEndDate(dayjs.tz(to).endOf('day'));
  }, [to]);

  /**
   * input from the Calendar
   * uses date-picker library
   */
  function onDateSelectedChange(dates) {
    const [start, end] = dates;

    setStartDate(dayjs.tz(dayjs(start).tz(user.timezone, true).startOf('day')));

    if (!end) {
      // restart range
      setEndDate(null);
    } else {
      setEndDate(dayjs.tz(dayjs(end).tz(user.timezone, true).endOf('day')));
    }
  }

  /**
   * input from the text input boxes
   */

  function onStartingInputChange(e) {
    setStartText(e.target.value);
    const validDate = dayjs(e.target.value, DATE_FORMAT_DASH, true).isValid();
    if (validDate) {
      setStartDate(dayjs.tz(e.target.value).startOf('day'));
    }
  }

  function onEndingInputChange(e) {
    setEndText(e.target.value);
    const validDate = dayjs(e.target.value, DATE_FORMAT_DASH, true).isValid();
    if (validDate) {
      setEndDate(dayjs.tz(e.target.value).endOf('day'));
    }
  }

  // Finalize the Date Range and sends the date up to the parent using callback function
  function onApplyClick(e) {
    // enable slection for single day range
    const finalStartDate = startDate;
    let finalEndDate = startDate.endOf('day');
    if (endDate != null) {
      finalEndDate = endDate;
    }

    setAppliedStartDate(startDate);
    setAppliedEndDate(finalEndDate);
    setShow(false);
    onChange({
      ...e,
      dateRange: {
        from: finalStartDate,
        to: finalEndDate,
      },
    });
    e.stopPropagation();
  }

  function onCancelClick() {
    setStartDate(appliedStartDate);
    setEndDate(appliedEndDate);
  }
  const header = (
    <div className="header">
      <div>
        <div>Starting</div>
        <input
          onChange={(e) => {
            onStartingInputChange(e);
          }}
          value={startText}
          type="text"
          name="starting"
          placeholder={fromPlaceHolder}
          autoComplete="off"
        />
      </div>
      <div>
        <div>Ending</div>
        <input
          onChange={(e) => onEndingInputChange(e)}
          value={endText}
          type="text"
          name="ending"
          placeholder={toPlaceHolder}
          autoComplete="off"
        />
      </div>
    </div>
  );
  const footer = (
    <div className="footer">
      <FeedFloButton onClick={(e) => onCancelClick(e)}>Cancel</FeedFloButton>
      <FeedFloButton
        onClick={(e) => {
          onApplyClick(e);
        }}
      >
        Apply
      </FeedFloButton>
    </div>
  );

  if (loading) {
    return (
      <div className="FeedFloTextInput">
        <LoadingSkeleton className="FeedFloTextInput-label--loader" />
        <LoadingSkeleton className="FeedFloTextInput-input--loader" />
      </div>
    );
  }

  return (
    <div className="FeedFloDateRangeInput">
      {label ? (
        <label className={`FeedFloTextInput--label ${disabled ? 'disabled' : ''} ${labelClassName}`}>{label}</label>
      ) : null}
      <div
        ref={buttonRef}
        className="FeedFloDateRangePicker-Button"
        onClick={() => {
          setShow(!show && !disabled);
        }}
      >
        <div className="FeedFloDateRangePicker-Button-icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {show && (
        <div className={`FeedFloDatePicker FeedFloDatePicker--panel ${className}`} ref={popupRef}>
          {header}
          <DatePicker
            className="Calendar"
            onChange={(dates) => onDateSelectedChange(dates)}
            startDate={startDate.tz(dayjs.tz.guess(), true).toDate()}
            endDate={endDate ? endDate.tz(dayjs.tz.guess(), true).toDate() : null}
            maxDate={maxDate ? maxDate.tz(dayjs.tz.guess(), true).toDate() : null} // to disable selection of future dates
            selectsRange
            inline
            disabledKeyboardNavigation
          />
          {footer}
        </div>
      )}
    </div>
  );
};

FeedFloDateRangeInput.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  max: PropTypes.object,
  onChange: PropTypes.func,
};

export default FeedFloDateRangeInput;
