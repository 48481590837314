import PropTypes from 'prop-types';
import { useField } from 'formik';
import ToggleSwitch from './ToggleSwitch';

export default function ToggleSwitchField({ ...props }) {
  const [field, , helpers] = useField(props);

  const { setValue } = helpers;

  return (
    <ToggleSwitch
      isActive={field.value === true}
      onToggle={(value) => {
        setValue(value);
        field?.onChange?.(value);
      }}
      {...props}
      {...field}
    />
  );
}

ToggleSwitchField.propTypes = {
  onChange: PropTypes.func,
};
