import React, { useState, useContext, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { DATE_FORMAT_DASH, TIME_FORMAT_UTC_OFFSET_TIMEZONE } from '../../utils/dates';
import { ExportIcon, Refresh } from '../../atoms/Icons';
import WebAppContext from '../../utils/webAppContext';
import { useAnalysisFilter } from '../../utils/useFeedFrameFilter';
import DocumentFactory from '../../utils/DocumentFactory/factory';
import CSVFeedEventStrategy from '../../utils/DocumentFactory/csvFeedEventStrategy';
import XlsxFeedEventStrategy from '../../utils/DocumentFactory/xlsxFeedEventStrategy';
import CSVFaultCodeStrategy from '../../utils/DocumentFactory/csvFaultCodeStrategy';
import XlsxFaultCodeStrategy from '../../utils/DocumentFactory/xlsxFaultCodeStrategy';
import FeedFloProgress from '../../atoms/FeedFloProgress';
import { ComboboxMultiField } from '../../atoms/ComboboxMulti';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SelectField } from '../../atoms/Select';
import Button from '../../atoms/Button';
import FeedFloDateRangeField from '../../organisms/FeedFloDateRangeInput/FeedFloDateRangeField';

const FARM_GQL = gql`
  query FarmDataQuery {
    farm(order_by: { name: asc }, where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`;

const FORMAT_LIST = [
  { id: 'xlsx', name: 'Excel .xlsx' },
  { id: 'csv', name: 'Comma Separated Values .csv' },
];

const DATA_TYPE_LIST = [
  { id: 'feedFrame', name: 'Feed Events' },
  { id: 'faultCode', name: 'Alerts' },
];

const UNIT_LIST = [
  { id: 'metric', name: 'Metric (kg)' },
  { id: 'imperial', name: 'Imperial (lbs)' },
];

const VALIDATION_SCHEMA = Yup.object().shape({
  dateRange: Yup.object().shape({
    from: Yup.date().required('Start date is required'),
    to: Yup.date().required('End date is required').min(Yup.ref('from'), 'End date cannot be before start date'),
  }),
  barnIds: Yup.array().of(Yup.string()).min(1, 'At least one barn must be selected'),
  dataType: Yup.string().required('Data type is required'),
  dataUnit: Yup.string().required('Data unit is required'),
  dataFormat: Yup.string().required('Data format is required'),
});

export default function ExportsController() {
  const gqlClient = useApolloClient();
  const [progress, setProgress] = useState(0);
  const [documentFactoryState, setDocumentFactoryState] = useState('ready');

  const { isMetric } = useContext(WebAppContext);
  const timeZoneString = dayjs.tz().format(TIME_FORMAT_UTC_OFFSET_TIMEZONE);

  const analysisFilter = useAnalysisFilter();
  const { data, loading } = useQuery(FARM_GQL);
  const farms = data?.farm;

  function startExport(to, from, barnIds, fileFormat, dataType, massUnitSystem) {
    setDocumentFactoryState('downloading');
    let strategy = null;
    switch (`${fileFormat}|${dataType}`) {
      case 'csv|feedFrame':
        strategy = new CSVFeedEventStrategy(massUnitSystem === 'metric');
        break;
      case 'xlsx|feedFrame':
        strategy = new XlsxFeedEventStrategy(massUnitSystem === 'metric');
        break;
      case 'csv|faultCode':
        strategy = new CSVFaultCodeStrategy();
        break;
      case 'xlsx|faultCode':
        strategy = new XlsxFaultCodeStrategy();
        break;

      default:
        break;
    }

    const factory = new DocumentFactory({
      farmIds: barnIds,
      startTime: from.toDate(),
      stopTime: to.toDate(),
      strategy,
      progressCallback: (x) => {
        setProgress(x);
        setDocumentFactoryState('downloading');
      },
      gqlClient,
      analysisFilter,
    });

    factory
      .start()
      .then(() => {
        const datePart = `${from.format(DATE_FORMAT_DASH)}_${to.format(DATE_FORMAT_DASH)}`;
        const barnPart = `${barnIds.length} barns`;
        const dataTypeString = dataType;
        try {
          saveAs(factory.createFile(), `${barnPart}_${dataTypeString}_${datePart}.${fileFormat}`);
        } catch (e) {
          Sentry?.captureException(e);
        }
      })
      .finally(() => {
        setDocumentFactoryState('done');
      });
  }

  const barnListItems = useMemo(() => {
    return farms?.map((f) => {
      return {
        id: f.id,
        name: f.name,
      };
    });
  }, [farms]);

  if (!analysisFilter) return <div>Loading...</div>;
  return (
    <div className="ExportController">
      <Formik
        onSubmit={(values) => {
          startExport(
            values.dateRange.to,
            values.dateRange.from,
            values.barnIds,
            values.dataFormat,
            values.dataType,
            values.dataUnit,
          );
        }}
        initialValues={{
          dateRange: { from: dayjs().subtract(6, 'months'), to: dayjs() },
          barnIds: [],
          dataType: 'feedFrame',
          dataUnit: isMetric ? 'metric' : 'imperial',
          dataFormat: 'xlsx',
        }}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <div className="ExportController-Form">
                <FeedFloDateRangeField
                  label={`Export Date Range ${timeZoneString}`}
                  name="dateRange"
                  max={dayjs()}
                  disabled={documentFactoryState !== 'ready'}
                />
                <ComboboxMultiField
                  name="barnIds"
                  items={barnListItems}
                  loading={loading}
                  label="Export Barns"
                  emptyPlaceholder="Select Barns"
                  itemNameSingular="Barn"
                  itemNamePlural="Barns"
                  allowSelectAll={true}
                  disabled={documentFactoryState !== 'ready'}
                />
                <SelectField
                  name="dataType"
                  defaultText="Select a Data Type"
                  label="Type of Data"
                  itemList={DATA_TYPE_LIST}
                  disabled={documentFactoryState !== 'ready'}
                />
                <SelectField
                  name="dataUnit"
                  defaultText="Select Weight Units"
                  label="Units"
                  disabled={documentFactoryState !== 'ready'}
                  itemList={UNIT_LIST}
                />
                <SelectField
                  name="dataFormat"
                  defaultText="Select Data Format"
                  label="File Format"
                  itemList={FORMAT_LIST}
                  disabled={documentFactoryState !== 'ready'}
                />
                {documentFactoryState === 'ready' && (
                  <Button
                    loading={loading}
                    disabled={documentFactoryState !== 'ready'}
                    variant="vivid"
                    color="success"
                    content={'Export'}
                    left={<ExportIcon className="white" />}
                    onClick={handleSubmit}
                  />
                )}
                {documentFactoryState === 'downloading' && <FeedFloProgress percent={progress} />}
                {documentFactoryState === 'done' && (
                  <Button
                    loading={loading}
                    variant="pastel"
                    color="success"
                    content={'Reset'}
                    left={<Refresh className="white" />}
                    onClick={() => setDocumentFactoryState('ready')}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
