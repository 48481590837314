import {
  orderRecommended,
  orderScheduled,
  orderShipped,
  orderDelivered,
  orderCancelled,
  tripMixed,
} from '../../colors.module.scss';

export const TripStatus = {
  Recommended: 'recommended',
  Scheduled: 'scheduled',
  Shipped: 'shipped',
  Delivered: 'delivered',
  Cancelled: 'cancelled',
  Mixed: 'mixed',
};

// Map to allow converting between internal and external names
export const TripStatusNameConverter = {
  [TripStatus.Recommended]: TripStatus.Recommended,
  [TripStatus.Scheduled]: 'ordered', // DB value is called 'ordered'
  ordered: TripStatus.Scheduled, // User-facing value is called 'scheduled'
  [TripStatus.Shipped]: TripStatus.Shipped,
  [TripStatus.Delivered]: TripStatus.Delivered,
  [TripStatus.Cancelled]: TripStatus.Cancelled,
  [TripStatus.Mixed]: TripStatus.Mixed,
};

export const TripStatusColor = {
  [TripStatus.Recommended]: orderRecommended,
  [TripStatus.Scheduled]: orderScheduled,
  [TripStatus.Shipped]: orderShipped,
  [TripStatus.Delivered]: orderDelivered,
  [TripStatus.Cancelled]: orderCancelled,
  [TripStatus.Mixed]: tripMixed,
};

export const ErrorMessage = {
  RequiredField: 'Field is required',
  Max255: 'Max field length is 255',
  PositiveNumber: 'Value must be a positive number',
  MustBePositive: 'Value must be positive',
  AtLeastOneDelivery:
    "An order must include at least one delivery. To cancel this order, press Cancel, reopen the order, and change its status to 'Canceled'.",
};
