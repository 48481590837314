export const BinSetCalibrationStatusEnum = {
  Uncalibrated: 'uncalibrated',
  Calibrated: 'calibrated',
  Due: 'due',
  PastDue: 'past_due',
};

export const FeedLineFullStatus = {
  Full: 'full',
  Empty: 'empty',
  Unknown: 'unknown',
};
export const FeedLineRunningStatus = {
  Running: 'running',
  Stopped: 'stopped',
  Unknown: 'unknown',
};
