export const LEDGER_TRANSACTION_REASON = {
  Mortality: 'mortality',
  Delivered: 'delivered',
  Shipped: 'shipped',
  Reconciliation: 'reconciliation',
};

export const LEDGER_TRANSACTION_REASON_NAMES = {
  [LEDGER_TRANSACTION_REASON.Mortality]: 'Mortality',
  [LEDGER_TRANSACTION_REASON.Delivered]: 'Delivered',
  [LEDGER_TRANSACTION_REASON.Shipped]: 'Shipped Out',
  [LEDGER_TRANSACTION_REASON.Reconciliation]: 'Reconciliation',
};

export const LEDGER_TRANSACTION_REASON_POLARITY = {
  [LEDGER_TRANSACTION_REASON.Mortality]: -1,
  [LEDGER_TRANSACTION_REASON.Delivered]: 1,
  [LEDGER_TRANSACTION_REASON.Shipped]: -1,
};

export const LEDGER_TRANSACTION_IN_ORDER = [
  LEDGER_TRANSACTION_REASON.Delivered,
  LEDGER_TRANSACTION_REASON.Shipped,
  LEDGER_TRANSACTION_REASON.Mortality,
  LEDGER_TRANSACTION_REASON.Reconciliation,
];
