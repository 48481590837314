import { useCallback, useMemo } from 'react';
import { max } from 'd3-array';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleTime } from '@visx/scale';
import PropTypes from 'prop-types';

import EventInnerChart from './EventInnerChart';
import EventChartBottom from './EventChartBottom';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

import { getY, calculateXAxisTickValues } from '../../utils/chartHelpers';

/**
 * Displays a line chart with legend and mouse-over tooltips.
 * Supports one or many line plots.
 *
 * Chart line objects have the following keys:
 * * linePlot: An array of {timestamp, value} point objects.
 * * label: A label or name describing this plot.
 * * colour: The colour to draw this plot.
 * * hidden: Do not draw this plot if true.
 * * onClick: Callback for when the label is clicked.
 *
 * Event objects have the following keys:
 * * row: Describes what row this event should be displayed in.
 * * colour: A {stroke, fill} object describing the colours for this event.
 * * selected: Describes if the event is selected.
 * * hidden: Describes if the event is hidden.
 * * onClick: Callback for when the event is clicked on.
 *
 * Row objects have the following keys:
 * * id: A unique identifier for this row. Must be unique. Not displayed to users.
 * * label: A label or name describing this row.
 * * colour: The colour used to draw this series..
 * * hidden: Whether this row is hidden on the chart. Hidden rows are ghosted and collapsed.
 * * reveal: A callback function that should un-hide the row and its events.
 *
 * @component
 */
function EventChart({
  chartLines: chartLineInput = [],
  chartBars = {},
  events = [],
  rows = [],
  start = 0,
  end = 0,
  inspectionWindow = null,
  snapToPoints = false,
  isMetric = false,
  chartHeight,
  chartMargin = { top: 0, bottom: 0, left: 0, right: 0 },
  loading = false,
}) {
  // When given a single chart element, add it to an array of size one for processing.
  const chartLines = useMemo(
    () => (Array.isArray(chartLineInput) ? chartLineInput : [chartLineInput]),
    [chartLineInput],
  );

  return (
    <ParentSize debounceTime={10}>
      {(parent) => {
        const xMax = parent.width - chartMargin.left - chartMargin.right;
        const yMax = chartHeight - chartMargin.top - chartMargin.bottom;

        // Scale functions to convert between raw values/timestamps and pixels.
        const xScale = useCallback(
          scaleTime({
            domain: [start, end],
            range: [0, xMax],
          }),
          [start, end, xMax],
        );
        const xBarScale = useCallback(
          scaleBand({
            domain: chartBars?.barPlot?.map((i) => i.timestamp) || [],
            range: [0, xMax],
            paddingInner: 0.2,
            paddingOuter: 0.1,
          }),
          [start, end, xMax, chartBars?.barPlot],
        );

        const yScale = useCallback(
          scaleLinear({
            // Y domain is between 0 and the largest Y value among all of the included line plots, increased by 20%.
            domain: [0, 1.2 * max(chartLines.map((chartLineData) => max(chartLineData.linePlot, getY)))],
            range: [yMax, 0],
          }),
          [chartLines, yMax],
        );
        const yBarScale = useCallback(
          scaleLinear({
            // Y domain is between 0 and the largest Y value among all of the included line plots, increased by 20%.
            domain: [0, (chartBars?.maxCapacity || 2000) / 10],
            range: [yMax, 0],
          }),
          [chartLines, yMax, '3'],
        );
        const xAxisTicks = calculateXAxisTickValues(start, end);

        if (loading) {
          return (
            <div
              style={{
                width: parent.width - chartMargin.left - chartMargin.right,
                marginLeft: chartMargin.left,
                marginRight: chartMargin.right,
              }}
            >
              <LoadingSkeleton
                style={{
                  height: chartHeight - chartMargin.top - chartMargin.bottom,
                  width: '100%',
                  marginTop: chartMargin.top,
                  marginBottom: chartMargin.bottom,
                }}
              />
              <LoadingSkeleton className="EventChart-loading" />
              <LoadingSkeleton className="EventChart-loading" />
            </div>
          );
        }

        return (
          <>
            <EventInnerChart
              chartLines={chartLines}
              chartBars={chartBars}
              events={events}
              start={start}
              end={end}
              height={chartHeight}
              width={parent.width}
              margin={chartMargin}
              xMax={xMax}
              yMax={yMax}
              xAxisTicks={xAxisTicks}
              xScale={xScale}
              yScale={yScale}
              xBarScale={xBarScale}
              yBarScale={yBarScale}
              inspectionWindow={inspectionWindow}
              snapToPoints={snapToPoints}
              isMetric={isMetric}
            />
            <EventChartBottom events={events} rows={rows} xMax={xMax} xScale={xScale} xAxisTicks={xAxisTicks} />
          </>
        );
      }}
    </ParentSize>
  );
}

EventChart.propTypes = {
  /**
   * An array containing line plot data.
   */
  chartLines: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        linePlot: PropTypes.arrayOf(
          PropTypes.shape({
            timestamp: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
        label: PropTypes.string,
        colour: PropTypes.string.isRequired,
        hidden: PropTypes.bool,
        onClick: PropTypes.func,
      }),
    ),
    PropTypes.shape({
      linePlot: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ).isRequired,
      label: PropTypes.string,
      colour: PropTypes.string.isRequired,
      hidden: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ]).isRequired,

  chartBars: PropTypes.object,
  /**
   * An object containing event data.
   */
  events: PropTypes.arrayOf(
    PropTypes.shape({
      points: PropTypes.arrayOf(PropTypes.number).isRequired,
      row: PropTypes.number.isRequired,
      colour: PropTypes.shape({
        fill: PropTypes.string.isRequired,
        stroke: PropTypes.string.isRequired,
        selected: PropTypes.bool,
        hidden: PropTypes.bool,
        onClick: PropTypes.func,
      }),
    }),
  ),
  /**
   * An array containing information about each event row.
   */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      colour: PropTypes.string.isRequired,
      hidden: PropTypes.bool,
      reveal: PropTypes.func,
    }),
  ),
  /**
   * The start timestamp in seconds since the epoch.
   */
  start: PropTypes.number.isRequired,
  /**
   * The end timestamp in seconds since the epoch.
   */
  end: PropTypes.number.isRequired,
  /**
   * Controls the visibility of projection lines and bars.
   */
  inspectionWindow: PropTypes.object,
  /**
   * Controls if tooltips snap to points or interpolate along lines.
   */
  snapToPoints: PropTypes.bool,
  /**
   * Controls the units for the chart Y-axis.
   */
  isMetric: PropTypes.bool,
  /**
   * The height of the chart in pixels. Does not affect the bottom row.
   */
  chartHeight: PropTypes.number.isRequired,
  /**
   * The margins of the chart. Only left and right will affect the bottom row.
   */
  chartMargin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  /**
   * Set to show a loading skeleton.
   */
  loading: PropTypes.bool,
};

export default EventChart;
