import PropTypes from 'prop-types';
import TextInput from './TextInput';
import { useField, useFormikContext } from 'formik';

export default function TextInputField({ label, ...props }) {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();

  return (
    <TextInput
      isValid={(!meta.touched && !submitCount) || !meta.error}
      error={meta.error}
      label={label}
      {...props}
      {...field}
    />
  );
}

TextInputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
