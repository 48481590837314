import { gql } from '@apollo/client';

export const ADD_FAULT_COMMENT_GQL = gql`
  mutation ConsumptionDisplay_InsertFaultComment($object: fault_comment_insert_input!) {
    insert_fault_comment_one(object: $object) {
      id
    }
  }
`;

export const CONSUMPTION_DISPLAY_GENERAL_GQL = gql`
  query ConsumptionDisplay_Query(
    $barnId: uuid!
    $endRangeTimestamp: bigint!
    $faultCodes: [Int!]!
    $maxDuration: bigint!
    $minDuration: bigint!
    $startRangeTimestamp: bigint!
    $interval: String
    $time_zone: String
  ) {
    feed_line(
      where: { deleted_at: { _is_null: true }, farm_id: { _eq: $barnId } }
      order_by: { bin_set: { bins_aggregate: { min: { name: asc } } }, name: asc }
    ) {
      id
      name
      device_assignments(
        where: {
          deleted_at: { _is_null: true }
          _or: [
            { _and: { status: { _eq: "active" }, ended_at: { _is_null: true } } }
            { _and: { started_at: { _lte: $endRangeTimestamp }, ended_at: { _gte: $startRangeTimestamp } } }
          ]
        }
        order_by: [{ started_at: desc_nulls_last }, { id: desc }]
      ) {
        started_at
        ended_at
        device {
          faults(
            where: {
              code: { _in: $faultCodes }
              duration: { _gte: $minDuration }
              deleted_at: { _is_null: true }
              feedflo_staff_only: { _eq: false }
              started_at: { _lte: $endRangeTimestamp }
              _or: [
                { ended_at: { _is_null: true }, duration: { _lte: $maxDuration } }
                { ended_at: { _gte: $startRangeTimestamp } }
              ]
            }
            order_by: { started_at: desc_nulls_last }
          ) {
            id
            code
            duration
            ended_at
            started_at
            nofeed_seconds
            fault_comments(
              where: { deleted_at: { _is_null: true } }
              order_by: { created_at: desc_nulls_last }
              limit: 1
            ) {
              comment
              fault_root_cause_id
            }
          }
          last_transaction: transactions(
            where: { deleted_at: { _is_null: true } }
            order_by: { occured_at: desc_nulls_last }
            limit: 1
          ) {
            occured_at
          }
        }
      }
    }
    farm(where: { id: { _eq: $barnId } }) {
      name
      rooms(where: { deleted_at: { _is_null: true } }) {
        id
        animal_inventory_summaries(
          args: {
            p_interval: $interval
            p_before: $endRangeTimestamp
            p_after: $startRangeTimestamp
            p_time_zone: $time_zone
          }
        ) {
          start_timestamp
          end_timestamp
          weighted_quantity
          max_quantity
          min_quantity
          mortality
          shipped
          delivered
          reconciliation
        }
        room_feed_lines(where: { deleted_at: { _is_null: true } }) {
          feed_line_id
        }
      }
    }
  }
`;

export const CONSUMPTION_BARN_INTERVAL = gql`
  query BarnFeed_interval(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      feed_frame_summaries(
        args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
      ) {
        start_timestamp
        end_timestamp
        total_consumption
      }
    }
  }
`;

export const CONSUMPTION_BARN_CUMULATIVE = gql`
  query BarnFeed_cumulative(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      feed_frame_summaries_cumulative(
        args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
      ) {
        start_timestamp
        end_timestamp
        total_consumption
      }
    }
  }
`;

export const CONSUMPTION_ROOM_INTERVAL = gql`
  query RoomFeed_interval(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      rooms(where: { deleted_at: { _is_null: true } }) {
        id
        name
        feed_frame_summaries(
          args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
        ) {
          start_timestamp
          end_timestamp
          total_consumption
        }
      }
    }
  }
`;
export const CONSUMPTION_ROOM_CUMULATIVE = gql`
  query RoomFeed_cumulative(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      rooms(where: { deleted_at: { _is_null: true } }) {
        id
        name
        feed_frame_summaries_cumulative(
          args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
        ) {
          start_timestamp
          end_timestamp
          total_consumption
        }
      }
    }
  }
`;

export const CONSUMPTION_BARN_INTERVAL_PER_ANIMAL = gql`
  query BarnFeed_interval(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      per_animal_feed_summaries(
        args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
      ) {
        start_timestamp
        end_timestamp
        per_animal_consumption
      }
    }
  }
`;

export const CONSUMPTION_BARN_CUMULATIVE_PER_ANIMAL = gql`
  query BarnFeed_cumulative(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      per_animal_feed_summaries_cumulative(
        args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
      ) {
        start_timestamp
        end_timestamp
        per_animal_consumption
      }
    }
  }
`;

export const CONSUMPTION_ROOM_INTERVAL_PER_ANIMAL = gql`
  query RoomFeed_interval(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      rooms(where: { deleted_at: { _is_null: true } }) {
        id
        name
        per_animal_feed_summaries(
          args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
        ) {
          start_timestamp
          end_timestamp
          per_animal_consumption
        }
      }
    }
  }
`;
export const CONSUMPTION_ROOM_CUMULATIVE_PER_ANIMAL = gql`
  query RoomFeed_cumulative(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      rooms(where: { deleted_at: { _is_null: true } }) {
        id
        name
        per_animal_feed_summaries_cumulative(
          args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
        ) {
          start_timestamp
          end_timestamp
          per_animal_consumption
        }
      }
    }
  }
`;

export const CONSUMPTION_FEEDLINE_INTERVAL = gql`
  query FeedLine_interval(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      feed_lines(
        where: { deleted_at: { _is_null: true } }
        order_by: { bin_set: { bins_aggregate: { min: { name: asc } } }, name: asc }
      ) {
        id
        name
        feed_frame_summaries: feed_frame_summaries_v2(
          args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
        ) {
          start_timestamp
          end_timestamp
          total_consumption
        }
      }
    }
  }
`;

export const CONSUMPTION_FEEDLINE_CUMULATIVE = gql`
  query FeedLine_cumulative(
    $barnId: uuid
    $start: bigint
    $end: bigint
    $interval: String
    $time_zone: String
    $alg: String
  ) {
    barn: farm(where: { id: { _eq: $barnId } }) {
      feed_lines(
        where: { deleted_at: { _is_null: true } }
        order_by: { bin_set: { bins_aggregate: { min: { name: asc } } }, name: asc }
      ) {
        id
        name
        feed_frame_summaries_cumulative(
          args: { p_after: $start, p_before: $end, p_interval: $interval, p_time_zone: $time_zone, p_alg: $alg }
        ) {
          start_timestamp
          end_timestamp
          total_consumption
        }
      }
    }
  }
`;
