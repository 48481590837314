import { gql } from '@apollo/client';

export const GetBarnSummaryDataQuery = gql`
  query BarnSummaryTab_GetBarnSummaryData(
    $barnId: uuid!
    $activeFaultWhere: fault_bool_exp!
    $deviceUnreachableFaultCode: Int!
    $feedFrameWhere: feed_frame_bool_exp
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
    $maxNoFeedCutoff: bigint = -28800 # 8 hours in seconds
    $binPredictionCutoff: bigint!
    $sha: String!
  ) {
    farm(where: { id: { _eq: $barnId }, deleted_at: { _is_null: true } }) {
      id
      bin_sets(
        where: { deleted_at: { _is_null: true } }
        order_by: [{ bins_aggregate: { min: { name: asc } } }, { created_at: desc }]
      ) {
        id
        bins(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
          id
          capacity_in_grams
          name
          last_delivery: deliveries(
            where: { status: { _eq: "delivered" }, deleted_at: { _is_null: true } }
            order_by: { delivered_at: desc_nulls_last }
            limit: 1
          ) {
            delivered_at
            trip_id
            status
          }
          scheduled_delivery: deliveries(
            where: { status: { _eq: "ordered" }, deleted_at: { _is_null: true } }
            order_by: { ordered_at: asc_nulls_last }
            limit: 1
          ) {
            ordered_at
            trip_id
            status
          }
          recommended_delivery: deliveries(
            where: { status: { _eq: "recomended" }, deleted_at: { _is_null: true } }
            order_by: { ordered_at: asc_nulls_last }
            limit: 1
          ) {
            ordered_at
            trip_id
            status
          }
          shipped_delivery: deliveries(
            where: { status: { _eq: "shipped" }, deleted_at: { _is_null: true } }
            order_by: { ordered_at: asc_nulls_last }
            limit: 1
          ) {
            ordered_at
            trip_id
            status
          }
        }
        bin_set_level_predictions(
          where: { predicted_at: { _gte: $binPredictionCutoff }, deleted_at: { _is_null: true } }
          order_by: { predicted_at: desc }
          limit: 1
        ) {
          bin_set_level_in_grams
          predicted_at
          empty_at
          earliest_delivery_at
          latest_order_by
        }
        feed_lines(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
          id
          name
          bin_set_id
          device_assignments(where: { status: { _eq: "active" }, deleted_at: { _is_null: true } }) {
            device {
              id
              serial
              feed_frames(where: $feedFrameWhere, order_by: [{ started_at: desc_nulls_last }, { id: asc }], limit: 1) {
                id
                started_at
                ended_at
                duration
                feed_frame_analyses(
                  where: $feedFrameAnalysisWhere
                  order_by: { created_at: desc_nulls_last }
                  limit: 1
                ) {
                  latest_estimated_mass_moved_in_grams
                }
              }
              active_device_unreachable_fault: faults(
                where: {
                  deleted_at: { _is_null: true }
                  ended_at: { _is_null: true }
                  code: { _eq: $deviceUnreachableFaultCode }
                }
                limit: 1
              ) {
                id
              }
              most_recent_transaction: transactions(
                where: { deleted_at: { _is_null: true } }
                order_by: { occured_at: desc_nulls_last }
                limit: 1
              ) {
                occured_at
              }
              active_alertable_fault_codes: faults(
                distinct_on: code
                where: { _and: [$activeFaultWhere], feedflo_staff_only: { _eq: false } }
              ) {
                id
                code
                nofeed_seconds
              }
            }
          }
          bin_set {
            tasks(
              limit: 1
              order_by: { task: { due_at: desc } }
              where: { task: { completed_at: { _is_null: true }, deleted_at: { _is_null: true } } }
            ) {
              task {
                due_at
                remind_at
                completed_at
              }
            }
          }
          feed_line_coefficients(
            limit: 1
            order_by: { created_at: desc }
            where: { algorithm_version: { _eq: $sha }, deleted_at: { _is_null: true } }
          ) {
            created_at
          }
        }
      }
    }
  }
`;
