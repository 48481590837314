import PropTypes from 'prop-types';
import Select from './Select';
import { useField, useFormikContext } from 'formik';

export default function SelectField({ label, itemList, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { submitCount } = useFormikContext();
  const { setValue } = helpers;

  return (
    <Select
      isValid={(!meta.touched && !submitCount) || !meta.error}
      error={meta.error}
      label={label}
      itemList={itemList}
      {...field}
      {...props}
      onChange={(event) => {
        setValue(event.target.value);

        props?.onChange?.(event);
      }}
    />
  );
}

SelectField.propTypes = {
  label: PropTypes.string,
  itemList: PropTypes.array,
  onChange: PropTypes.func,
};
