import PropTypes from 'prop-types';
import { useField } from 'formik';
import FeedFloDateRangeInput from './FeedFloDateRangeInput';

export default function FeedFloDateRangeField({ label, ...props }) {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  return (
    <FeedFloDateRangeInput
      isValid={!meta.touched || (meta.touched && !meta.error)}
      error={meta.error}
      label={label}
      to={field.value?.to}
      from={field.value?.from}
      {...field}
      {...props}
      onChange={(event) => {
        setValue(event.dateRange);
      }}
    />
  );
}

FeedFloDateRangeField.propTypes = {
  label: PropTypes.string,
};
