import PropTypes from 'prop-types';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import InventoryChart from './InventoryChart';
import FeedFloToolTip from '../../atoms/FeedFloToolTip';
import { InfoIcon } from '../../atoms/Icons';
import FeedFloDateRangeInput from '../FeedFloDateRangeInput';

function InventoryCardView({
  chartLoading = false,
  data = {},
  totalCapacity,
  chartSeries = [],
  deliveries = [],
  dataPaneChildren = <></>,
  dateRange,
  onDateRangeChange,
  feedlines,
}) {
  const colourPalette = {
    calculated: '#26AF5F',
    projected: '#26AF5F', // same colour as calculated to show it continues
    historicDataColour: '#9be09b', // historic live data aka Calibrated (Live Data)
    deliveries: '#217BCE',
    truthData: '#65C9DA',
    binSetLevel: '#f2c94c', // purpose "Test" (not used for anything but visuals)
    binSetLevelCorrection: '#8B7229', // AKA Reset/Snap
    binSetLevelCalibration: '#4682B4', // purpose "Calibrate"
    calibrations: '#BA6057', // Visualing when a coefficient is used
  };

  const chartLines = [
    { label: 'Bin Level', colour: colourPalette.calculated },
    { label: 'Projected as Scheduled', colour: '#0ba5ec' },
    { label: 'Projected as Recommended', colour: '#ddbaf3' },
  ];

  const renderChart = () => {
    if (chartLoading) {
      return (
        <>
          <div className="InventoryCard-chartContainer">
            <LoadingSkeleton className="InventoryCard-chartContainer--loader" />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="InventoryCard-centerContainer">
          <div className="InventoryCard-chartContainer">
            <InventoryChart
              startDate={dateRange.from.unix()}
              endDate={dateRange.to.unix()}
              chartSeries={chartSeries}
              deliveries={deliveries}
              loading={chartSeries?.[0]?.x.length < 1}
              totalCapacityInGrams={totalCapacity}
            ></InventoryChart>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="InventoryCard">
      <div className="InventoryCard-ChartAndHeaders">
        <div className="InventoryCard-row">
          <div>
            <h3>{`Bins ${data?.bins || 'Loading'}`}</h3>
            <FeedFloToolTip description={`${feedlines.join(', ')}`} position="bottom">
              <InfoIcon />
            </FeedFloToolTip>
          </div>

          <FeedFloDateRangeInput
            onChange={(e) => {
              onDateRangeChange(e.dateRange);
            }}
            max={null}
            from={dateRange?.from.toDate()}
            to={dateRange?.to.toDate()}
          />
        </div>
        {renderChart()}
      </div>
      <div className="InventoryCard-dataPaneContainer">
        {dataPaneChildren || (
          <>
            <h4>Legend</h4>
            <hr />
            <div className="CalibrationChart-legend">
              {chartLines.map(({ label, colour }, index) => {
                return (
                  <div className="legendItem" key={index}>
                    <div className="left">
                      <div className="circle" style={{ backgroundColor: colour }} />
                      {label}
                    </div>
                    <div className="icons"></div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

InventoryCardView.propTypes = {
  chartLoading: PropTypes.bool,
  dateRange: PropTypes.object,
  totalCapacity: PropTypes.number,
  data: PropTypes.object,
  chartSeries: PropTypes.array,
  deliveries: PropTypes.array,
  dataPaneChildren: PropTypes.element,
  onDateRangeChange: PropTypes.func,
  feedlines: PropTypes.array,
};

export default InventoryCardView;
