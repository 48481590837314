import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import EventAccordionPanel from './EventAccordionPanel';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

import './EventAccordion.scss';
import ComboboxMulti from '../../atoms/ComboboxMulti';

/**
 * Each event is presented in a collapsible panel, allowing users to expand for more information.
 *
 * Events accept the following keys:
 *
 * * These keys control how and when the event panel is displayed:
 *
 *   category: string. Used for category filtering. Should match one of the category ids given to the 'categories' prop.
 *
 *   hidden: boolean. Controls if this event panel is shown or not. Defaults to false if not provided.
 *
 *   expanded: boolean. Controls if this event panel is expanded or collapsed. Defaults to false if not provided.
 *
 *   onPanelClick: function. Called with no parameters when user clicks to expand or collapse the panel.
 *
 * * These keys control how the event panel appears when collapsed and expanded:
 *
 *   content: JSX. The main content to be displayed in the header.
 *
 *   left: JSX. Additional content displayed on the left side of the header.
 *
 *   right: JSX. Additional content displayed on the right side of the header.
 *
 * * These keys control how the panel appears only when expanded:
 *
 *   details: string. Additional info displayed when the event panel is expanded.
 *
 *   notes: string. User-entered text displayed when the event panel is expanded.
 *
 *   saveNote: function. Called with one string parameter (note) when the user saves a new note.
 *
 *   linkText: string. Text shown as a link at the bottom of the panel.
 *
 *   linkTo: string. An absolute or partial URL that will be passed to the Link component.
 *
 * @component
 */
function EventAccordion({
  className = '',
  categories = [],
  events = [],
  visibleCategories = [],
  setVisibleCategories = () => null,
  scrollToEventId = null,
  loading = false,
}) {
  // A dict of refs to refer to each panel. Used to enable scrolling.
  const panelRefs = useRef({});
  // The dict is emptied at the start of each render and re-populated as the panels are built.
  panelRefs.current = {};

  useEffect(() => {
    const target = panelRefs.current?.[scrollToEventId];
    if (!target) return;
    target.parentNode.scrollTo({ top: target.offsetTop - target.parentNode.offsetTop, behavior: 'smooth' });
  }, [scrollToEventId]);

  // Add 'selected: true' to the list of 'id' and 'name'.
  const dropDownCategories = useMemo(
    () =>
      categories.map((category) => {
        return { ...category, selected: true };
      }),
    [categories],
  );

  const displayedEvents = useMemo(
    () =>
      events.reduce((displayedEvents, { id: eventId, category, hidden, ...eventProps }) => {
        if (!hidden && visibleCategories.includes(category)) {
          /**
           * Callback used to collect a div ref into a dict, keyed by event ID.
           */
          const updatePanelRefs = (divRef) => {
            panelRefs.current[eventId] = divRef;
          };

          displayedEvents.push(
            <div id={eventId} key={eventId} ref={updatePanelRefs} className="EventAccordion-panelContainer">
              <EventAccordionPanel className="EventAccordion-panel" {...eventProps} />
            </div>,
          );
        }
        return displayedEvents;
      }, []),
    [events, visibleCategories],
  );

  if (loading) {
    return (
      <div className={`EventAccordion ${className}`}>
        <div className="EventAccordion-header">
          <p className="EventAccordion-headerText">Events</p>
          <LoadingSkeleton className="EventAccordion-dropdown--loading" />
        </div>
        <div className="EventAccordion-panels">
          {Array(6)
            .fill()
            .map((_, index) => (
              <EventAccordionPanel className="EventAccordion-panel" loading key={index} />
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`EventAccordion ${className}`}>
      <div className="EventAccordion-header">
        <ComboboxMulti
          label="Event Filter"
          defaultTitle="Filter"
          itemNameSingular="Type"
          itemNamePlural="Types"
          items={dropDownCategories}
          allowSelectAll={true}
          value={visibleCategories}
          onChange={(e) => {
            setVisibleCategories(e.map((i) => i.id));
          }}
        />
      </div>

      {displayedEvents.length === 0 ? (
        <div className="EventAccordion-emptyEventList">
          <img className="EventAccordion-logo" src="/images/Feedflo-logo.svg" alt="FeedFlo Logo" />
          <p className="EventAccordion-emptyEventListText">No events in the selected time frame</p>
        </div>
      ) : (
        <div className="EventAccordion-panels">{displayedEvents}</div>
      )}
    </div>
  );
}

EventAccordion.propTypes = {
  /**
   * An array of events objects.
   */
  events: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      hidden: PropTypes.bool,
      expanded: PropTypes.bool,
      onPanelClick: PropTypes.func,

      content: PropTypes.element,
      left: PropTypes.element,
      right: PropTypes.element,

      details: PropTypes.string,
      notes: PropTypes.string,
      saveNote: PropTypes.func,
      linkText: PropTypes.string,
      linkTo: PropTypes.string,
    }),
  ),
  /**
   * An array of objects with 'id' and 'name' keys used to populate the category filter drop-down.
   */
  categories: PropTypes.array,
  /**
   * An array each category ID that can be seen.
   */
  visibleCategories: PropTypes.array,
  /**
   * A callback function for updating visibleCategories state in the parent.
   */
  setVisibleCategories: PropTypes.func,
  /**
   * An event ID for scrolling the accordion to a specific panel.
   */
  scrollToEventId: PropTypes.string,
  /**
   * Default CSS class override.
   */
  className: PropTypes.string,
  /**
   * Set to show a loading skeleton.
   */
  loading: PropTypes.bool,
};

export default EventAccordion;
