import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Card from '../../atoms/Card';
import LabelledColumn from '../../atoms/LabelledColumn';
import StatusBadge from '../../atoms/StatusBadge';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import { RightChevronIcon } from '../../atoms/Icons';
import { SingleBinIcon, DoubleBinIcon } from '../../atoms/BinIcons';

import { convertGramsToLargeUnits } from '../../utils/unitConversion';
import { DATE_FORMAT_DASH, DATE_FORMAT_MONTH_DAY_YEAR, DATE_FORMAT_WEEKDAY_SHORT_MONTH_DAY } from '../../utils/dates';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';

import './BinSetStatusCard.scss';
import { Link } from 'react-router-dom';
import withStaffOnlyIdWrapper from '../../utils/withStaffOnlyIdWrapper';

const BinState = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  UNKNOWN: 'unknown',
});

const BinStateColour = Object.freeze({
  [BinState.ERROR]: '#c93624', // Red 900
  [BinState.WARNING]: '#eee276', // Yellow 600
  [BinState.SUCCESS]: '#188A47', // Green 800
});

const OrderState = Object.freeze({
  RECOMMENDED: 'recommended',
  SHIPPED: 'shipped',
  SCHEDULED: 'ordered',
  DELIVERED: 'delivered',
});
const OrderStateString = Object.freeze({
  [OrderState.RECOMMENDED]: 'Order Recommended for',
  [OrderState.SHIPPED]: 'Order Shipped on',
  [OrderState.SCHEDULED]: 'Order Scheduled for',
  [OrderState.DELIVERED]: 'Order Delivered on',
});

function calcBinState(predictedAt, earliestDeliveryAt, latestOrderBy) {
  if (predictedAt === 0 || earliestDeliveryAt === 0 || latestOrderBy === 0) return BinState.UNKNOWN;
  if (predictedAt >= latestOrderBy) return BinState.ERROR;
  else if (predictedAt >= earliestDeliveryAt) return BinState.WARNING;
  else return BinState.SUCCESS;
}

function calcEmptyIn(emptyAt) {
  if (emptyAt === 0) {
    return {
      emptyInHeading: 'Empty In',
      emptyInString: 'Unknown',
    };
  }
  const emptyAtDayjs = dayjs.tz(1000 * emptyAt);
  if (emptyAtDayjs <= dayjs.tz()) {
    // Empty at date is in the past.
    return { emptyInHeading: 'Empty Since', emptyInString: emptyAtDayjs.format(DATE_FORMAT_MONTH_DAY_YEAR) };
  } else {
    const emptyInDuration = dayjs.duration(dayjs.tz(emptyAt * 1000).diff(dayjs.tz()));
    const emptyInDays = Math.floor(emptyInDuration.asDays());
    const emptyInHours = Math.floor(emptyInDuration.asHours());
    return {
      emptyInHeading: 'Empty In',
      emptyInString: emptyInDays >= 2 ? `${emptyInDays} days` : `${emptyInHours} hour${emptyInHours !== 1 ? 's' : ''}`,
    };
  }
}

function BinSetStatusCard({
  className = '',
  loading = false,
  bins = [],
  feedLineNames = [],
  binSetLevelInGrams = 0,
  predictedAt = 0,
  emptyAt = 0,
  earliestDeliveryAt = 0,
  latestOrderBy = 0,
  isMetric = false,
  order,
  id = '',
}) {
  const { censor } = useCensor();

  if (loading) {
    return (
      <Card
        className={`BinSetStatusCard ${className}`}
        contentClassName="BinSetStatusCard-cardContentWrapper"
        status="none"
      >
        <svg width="0" height="0">
          <defs>
            <clipPath id="bin-set-loading-icon">
              <rect x="55.3354" y="41.5121" width="6.97688" height="5.17353" />
              <path d="M55.3333 72.2395L41.7986 101.07H14.6319L1 72.2395V13.2071H55.3333V72.2395Z" />
              <path d="M116.692 72.2395L103.157 101.07H75.9908L62.3589 72.2395V13.2071H116.692V72.2395Z" />
              <path d="M35.8938 3.59686H20.4395L1 13.1962H55.3333L35.8938 3.59686Z" />
              <path d="M97.2527 3.59686H81.7983L62.3589 13.1962H116.692L97.2527 3.59686Z" />
              <path d="M34.2949 1H22.0372C21.1408 1 20.4172 1.72346 20.4172 2.61969V3.6347H35.9148V2.61969C35.9148 1.72346 35.1912 1 34.2949 1Z" />
              <path d="M95.6537 1H83.3961C82.4997 1 81.7761 1.72346 81.7761 2.61969V3.6347H97.2737V2.61969C97.2737 1.72346 96.5501 1 95.6537 1Z" />
            </clipPath>
          </defs>
        </svg>
        <LoadingSkeleton className="BinSetStatusCard-title BinSetStatusCard-loadingShort" />
        <LoadingSkeleton className="BinSetStatusCard-binSetIcon BinSetStatusCard-loadingIcon" />
        <LabelledColumn
          className="BinSetStatusCard-feedLineNames BinSetStatusCard-loadingMid"
          heading="Feed Lines"
          loading
        />
        <LabelledColumn className="BinSetStatusCard-capacity BinSetStatusCard-loadingMid" heading="Remaining" loading />
        <LabelledColumn className="BinSetStatusCard-emptyIn BinSetStatusCard-loadingMid" heading="Empty In" loading />
        <LabelledColumn
          className="BinSetStatusCard-lastDelivery BinSetStatusCard-loadingMid"
          heading="Last Delivery"
          loading
        />
      </Card>
    );
  }

  const BinIcon = 1 === bins.length ? SingleBinIcon : DoubleBinIcon;
  const binNames = bins.map(({ name }) => name || '?');
  const name = `Bin${binNames.length > 1 ? 's' : ''} ${binNames.join(', ')}`;

  const capacityInGrams = bins.reduce((sum, { capacityInGrams }) => (sum += capacityInGrams), 0);
  const capacityInLargeUnits = convertGramsToLargeUnits(isMetric, capacityInGrams, 0);
  const binSetLevelInLargeUnits =
    binSetLevelInGrams != 0 ? convertGramsToLargeUnits(isMetric, binSetLevelInGrams, 1) : '?';
  const fillPercent = (100 * binSetLevelInGrams) / (capacityInGrams || 1);

  const { emptyInHeading, emptyInString } = calcEmptyIn(emptyAt);

  const lastDelivery = bins.reduce((lastDelivery, bin) => Math.max(lastDelivery, bin.lastDelivery || 0), 0);

  let orderTime = order?.delivered_at || order?.ordered_at;

  const binState = calcBinState(predictedAt, earliestDeliveryAt, latestOrderBy);
  const binStateErrorHighlight =
    BinState.ERROR === binState && OrderState.SCHEDULED !== order?.status && OrderState.SHIPPED !== order?.status;

  const binSetName = censor(name, censorTypes.binset);
  const IdWrappedTitle = withStaffOnlyIdWrapper(binSetName);

  return (
    <div className="BinSetStatusCard">
      <Card
        className={`BinSetStatusCard-card ${binStateErrorHighlight ? 'BinSetStatusCard-card--error' : ''} ${
          order?.status ? 'BinSetStatusCard-card--order' : ''
        } ${className}`}
        contentClassName="BinSetStatusCard-cardContentWrapper"
        status={BinState.ERROR === binState ? 'error' : 'none'}
        accentLocation="top"
      >
        <span className="BinSetStatusCard-title BinSetStatusCard-titleText">
          <IdWrappedTitle data={id} hoverText="Copy Bin Set ID" />
        </span>
        <BinIcon
          className="BinSetStatusCard-binSetIcon"
          fillPercent={fillPercent}
          fillColour={BinStateColour[binState]}
        />
        <LabelledColumn className="BinSetStatusCard-feedLineNames" heading="Feed Lines">
          {feedLineNames.map((name, index) => (
            <p className="BinSetStatusCard-contentText" key={index}>
              {censor(name, censorTypes.feedline)}
            </p>
          ))}
        </LabelledColumn>
        <LabelledColumn className="BinSetStatusCard-capacity" heading="Remaining">
          <span className="BinSetStatusCard-capacityStrongText">{binSetLevelInLargeUnits}</span>
          <span className="BinSetStatusCard-capacityText">{` / ${capacityInLargeUnits} ${
            isMetric ? 'Tonnes' : 'Tons'
          }`}</span>
        </LabelledColumn>
        <LabelledColumn className="BinSetStatusCard-emptyIn" heading={emptyInHeading}>
          <span className="BinSetStatusCard-contentText">
            {binStateErrorHighlight ? <StatusBadge text={emptyInString.toUpperCase()} status="error" /> : emptyInString}
          </span>
        </LabelledColumn>
        {lastDelivery ? (
          <LabelledColumn className="BinSetStatusCard-lastDelivery" heading="Last Delivery">
            <span className="BinSetStatusCard-contentText">
              {dayjs.tz(1000 * lastDelivery).format(DATE_FORMAT_DASH)}
            </span>
          </LabelledColumn>
        ) : (
          <></>
        )}
      </Card>
      {order && (
        <Link to={`/FeedDesk?trip=${order.trip_id}`}>
          <Card
            className={`BinSetStatusCard-order ${binStateErrorHighlight ? 'BinSetStatusCard-order--error' : ''}`}
            contentClassName="BinSetStatusCard-orderContent"
            accentLocation="left"
            status={order.status || 'none'}
          >
            {OrderStateString[order.status]} - {dayjs.unix(orderTime).format(DATE_FORMAT_WEEKDAY_SHORT_MONTH_DAY)}
            <RightChevronIcon className="BinSetStatusCard-chevronIcon" />
          </Card>
        </Link>
      )}
    </div>
  );
}

BinSetStatusCard.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  order: PropTypes.object,
  bins: PropTypes.arrayOf(
    PropTypes.shape({
      capacityInGrams: PropTypes.number,
      name: PropTypes.string,
      lastDelivery: PropTypes.number,
      recommendedDelivery: PropTypes.number,
      scheduledDelivery: PropTypes.number,
      shippedDelivery: PropTypes.number,
    }),
  ),
  feedLineNames: PropTypes.arrayOf(PropTypes.string),
  binSetLevelInGrams: PropTypes.number,
  predictedAt: PropTypes.number,
  emptyAt: PropTypes.number,
  earliestDeliveryAt: PropTypes.number,
  latestOrderBy: PropTypes.number,
  isMetric: PropTypes.bool,
  id: PropTypes.string,
};

export default BinSetStatusCard;
