import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import './Select.scss';

function Select({
  loading = false,
  inputRef = null,
  className = '',
  labelClassName = '',
  inputClassName = '',
  descriptionClassName = '',
  tabIndex = 0,
  isValid = true,
  disabled = false,
  name = '',
  label = '',
  left = null,
  right = null,
  defaultText = '',
  itemList = [],
  value = '',
  description = '',
  onChange = () => {},
  onBlur = () => {},
}) {
  const renderInput = () => {
    return (
      <div className="Select-inputContainer">
        {left}
        <select
          ref={inputRef}
          className={`Select-input ${!isValid ? 'Select-input--error' : ''} ${inputClassName}`}
          tabIndex={tabIndex}
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        >
          {defaultText ? <option value="">{defaultText}</option> : null}
          {itemList.map((item, index) => (
            <option key={index + '' + (item.id || item.name) + '' + item.value} value={item.id || item.value}>
              {item.name}
            </option>
          ))}
        </select>
        {right}
      </div>
    );
  };

  if (loading) {
    return (
      <div className={`Select ${className}`}>
        {label ? <LoadingSkeleton className="Select-labelText--loader" /> : null}
        <LoadingSkeleton className="Select-input--loader" />
        {description ? <LoadingSkeleton className="Select-description--loader" /> : null}
      </div>
    );
  }

  return (
    <div className={`Select ${disabled ? 'Select--disabled' : ''} ${className}`}>
      {label ? (
        <label className="Select-label">
          <div className={`Select-labelText ${!isValid ? 'Select-labelText--error' : ''} ${labelClassName}`}>
            {label}
          </div>
          {renderInput()}
        </label>
      ) : (
        renderInput()
      )}
      {description ? (
        <div className={`Select-description ${!isValid ? 'Select-description--error' : ''} ${descriptionClassName}`}>
          {description}
        </div>
      ) : null}
    </div>
  );
}

Select.propTypes = {
  loading: PropTypes.bool,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultText: PropTypes.string,
  itemList: PropTypes.array,
  value: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Select;
