import PropTypes from 'prop-types';
import ComboboxMulti from './ComboboxMulti';
import { useField } from 'formik';

export default function ComboboxField({ label, ...props }) {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  return (
    <ComboboxMulti
      isValid={!meta.error}
      error={meta.error}
      label={label}
      {...field}
      {...props}
      onChange={(changes) => {
        setValue(changes.map((item) => item?.id || item?.value));
        // try to handle custom onChange though this does NOT return
        // typical React synthetic events
        props?.onChange?.(changes);
      }}
    />
  );
}

ComboboxField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};
