import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PinIcon } from '../../atoms/Icons';
import BarnBlockStat from './BarnBlockStat';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import StatusIcon from '../../atoms/StatusIcon';
import './BarnBlock.scss';

function BarnBlock({
  loading = false,
  className = '',
  id = '',
  viewMode = 'card',
  status = 'success',
  isPinned = false,
  name = '',
  orgName = '',
  address = '',
  statValue = '',
  statUnit = '',
  statLabel = '',
  statValue2 = null,
  statUnit2 = null,
  statLabel2 = null,
  children = null,
  isPreviewItem = false,
  onPinBarnClick = () => {},
}) {
  const onPinClick = (e) => {
    e.preventDefault();
    onPinBarnClick(id, isPinned);
  };

  if (loading) {
    if (viewMode === 'card') {
      return (
        <LoadingSkeleton
          className={`BarnBlock-cardItem--loading ${isPreviewItem ? 'BarnBlock-cardItem--preview' : ''}`}
        />
      );
    } else {
      return (
        <div className="BarnBlock-listItem">
          <div className="BarnBlock-listContent">
            <LoadingSkeleton className="BarnBlock-listSegment--loading" />
            <LoadingSkeleton className="BarnBlock-listSegment--loading" />
            <LoadingSkeleton className="BarnBlock-listSegment--loading" />
            <LoadingSkeleton className="BarnBlock-listSegment--loading" />
            <LoadingSkeleton className="BarnBlock-listSegment--loading" />
            <LoadingSkeleton className="BarnBlock-listSegment--loading" />
          </div>
        </div>
      );
    }
  }

  if (viewMode === 'card') {
    return (
      <div
        className={`BarnBlock-cardItem BarnBlock-cardItem--${status} ${
          isPreviewItem ? 'BarnBlock-cardItem--preview' : ''
        }`}
      >
        <div
          className={`BarnBlock-pinBack ${isPinned ? 'BarnBlock-pinBack--pinned' : 'BarnBlock-pinBack--unpinned'}`}
          onClick={onPinClick}
        />
        <Link className="BarnBlock-link" to={`/b/${id}`}>
          <div className={`BarnBlock-cardContent BarnBlock-cardContent--${status}`}>
            <div className={`BarnBlock-accent BarnBlock-accent--${status}`}>
              <div className="BarnBlock-headerContainer">
                <div className="BarnBlock-iconRow">
                  <StatusIcon className="BarnBlock-icon" status={status} />
                </div>
                <div className="BarnBlock-cardName">{name}</div>
                <div className="BarnBlock-address">{address}</div>
                {orgName && <div className="BarnBlock-orgName">{orgName}</div>}
              </div>
              <div className="BarnBlock-statContainer">
                <BarnBlockStat value={statValue} unit={statUnit} label={statLabel} />
                {!!statValue2 && <BarnBlockStat value={statValue2} unit={statUnit2} label={statLabel2} />}
              </div>
              <div className="BarnBlock-cardStatusList">{children}</div>
            </div>
          </div>
        </Link>
        <div
          className={`BarnBlock-pinFront ${isPinned ? 'BarnBlock-pinFront--pinned' : 'BarnBlock-pinFront--unpinned'}`}
          onClick={onPinClick}
        >
          <PinIcon className="BarnBlock-cardPinIcon" />
        </div>
      </div>
    );
  } else {
    return (
      <div className={`BarnBlock-listItem BarnBlock-listItem--${status} ${className}`}>
        <Link className="BarnBlock-link" to={`/b/${id}`}>
          <div className={`BarnBlock-listContent BarnBlock-listContent--${status}`}>
            <div className="BarnBlock-listSegment BarnBlock-icon">
              <StatusIcon className="BarnBlock-icon" status={status} />
            </div>
            <div className="BarnBlock-listSegment BarnBlock-listName">
              {name}
              {orgName ? ` (${orgName})` : ''}
            </div>
            <div className="BarnBlock-listSegment BarnBlock-address">{address}</div>
            <div className="BarnBlock-listSegment BarnBlock-listStatusList">{children}</div>
            <div className="BarnBlock-listSegment BarnBlock-nofeedTime">{`${statValue} ${statUnit}`}</div>
            <div className="BarnBlock-listSegment BarnBlock-pin">
              <PinIcon
                className={`BarnBlock-listSegment BarnBlock-listPinIcon BarnBlock-listPinIcon--${
                  isPinned ? 'pinned' : 'unpinned'
                }`}
                onClick={onPinClick}
              />
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

BarnBlock.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  viewMode: PropTypes.oneOf(['card', 'list']),
  status: PropTypes.string,
  isPinned: PropTypes.bool,
  orgName: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  statValue: PropTypes.string,
  statUnit: PropTypes.string,
  statLabel: PropTypes.string,
  statValue2: PropTypes.string,
  statUnit2: PropTypes.string,
  statLabel2: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isPreviewItem: PropTypes.bool,
  onPinBarnClick: PropTypes.func,
};

export default BarnBlock;
