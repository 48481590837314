import { useState } from 'react';
import EditFeederModal from './EditFeederModal';
import Button from '../../atoms/Button';
import { GearIcon } from '../../atoms/Icons';
import PropTypes from 'prop-types';

function EditFeederModalTrigger({ feedLineName, feedLineIndex, feederIndex }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        type="primary"
        content={<GearIcon />}
        variant="text"
        color="success"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      />
      {isOpen && (
        <EditFeederModal
          feedLineName={feedLineName}
          feedLineIndex={feedLineIndex}
          feederIndex={feederIndex}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
EditFeederModalTrigger.propTypes = {
  feedLineName: PropTypes.string,
  feedLineIndex: PropTypes.number,
  feederIndex: PropTypes.number,
};
export default EditFeederModalTrigger;
