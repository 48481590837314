import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import Button from '../../atoms/Button';
import Dialog from '../../atoms/Dialog';

import { DATE_FORMAT_DASH } from '../../utils/dates';
import { ANIMAL_GROUP_HEADER_QUERY } from './queries';
import './AnimalGroupHeader.scss';
import ComboboxMulti from '../../atoms/ComboboxMulti/ComboboxMulti';

/**
 * A page header to display, view, and create animal groups at a given barn.
 * Expose the start and end date for the selected group to children through the
 *  Function as Child Component (FaCC) pattern.
 *
 * The child function should return JSX and accept an object with 'from' and 'to' keys
 *  containing the UNIX timestamp representations of the start and end dates for the selected group.
 *  Optional if the header doesn't need to control another component.
 *
 * @component
 */
function AnimalGroupHeader({
  loading = false,
  className = '',
  barnId = '',
  allowGroupSelection = true,
  children = () => {},
}) {
  const selectorDialogRef = useRef(null);

  const [animalGroupIds, setAnimalGroupIds] = useState([]);

  const { data } = useQuery(ANIMAL_GROUP_HEADER_QUERY, {
    variables: {
      barnId,
    },
  });

  useEffect(() => {
    const newSelected = data?.animal_group?.filter((g) => !g.ended_at).map((g) => g.id);
    setAnimalGroupIds(newSelected);
  }, [data?.animal_group]);

  const openSelector = useCallback(() => selectorDialogRef.current.showModal(), []);

  const dateRange =
    data?.animal_group
      ?.filter((g) => animalGroupIds?.includes(g.id))
      .reduce((dateRange, group) => {
        if (dateRange === null) dateRange = {};
        const g_started_at = dayjs.tz(1000 * group.started_at).startOf('day');
        const g_ended_at = group.ended_at ? dayjs.tz(1000 * group.ended_at).endOf('day') : dayjs.tz().endOf('day');

        if (!dateRange.from || g_started_at.isBefore(dateRange.from)) dateRange.from = g_started_at;

        if (!dateRange.to || (g_ended_at && g_ended_at.isAfter(dateRange.to))) dateRange.to = g_ended_at;

        return dateRange;
      }, null) || null;

  return (
    <>
      <div className={`AnimalGroupHeader ${className}`}>
        <div className="AnimalGroupHeader-info">
          <label>Group ID</label>
          <label>Start Date</label>
          <label>End Date</label>
          <label>Animals In</label>
          <label>Remaining</label>

          {data?.animal_group
            ?.filter((group) => animalGroupIds?.includes(group.id))
            .map((group) => {
              return (
                <Fragment key={group.external_id}>
                  <div>{group.external_id}</div>
                  <div>{(group.started_at && dayjs.tz(1000 * group.started_at).format(DATE_FORMAT_DASH)) || '-'}</div>
                  <div>{(group.ended_at && dayjs.tz(1000 * group.ended_at).format(DATE_FORMAT_DASH)) || '-'}</div>
                  <div>{group.peak_inventory}</div>
                  <div>{group.latest_inventory}</div>
                </Fragment>
              );
            })}
          {data?.animal_group?.filter((group) => animalGroupIds?.includes(group.id))?.length < 1 && (
            <>No Group Selected</>
          )}
        </div>
        {allowGroupSelection && (
          <div className="AnimalGroupHeader-buttons">
            <Button
              disabled={loading}
              variant="pastel"
              color="success"
              content="Select Animal Group"
              onClick={openSelector}
            />
          </div>
        )}
        <Dialog className="AnimalGroupHeader-selectorDialog" ref={selectorDialogRef}>
          <div className="AnimalGroupHeader-selectorDialog-Inner">
            <ComboboxMulti
              label="Select Animal Groups to Display"
              items={data?.animal_group.map((g) => {
                return { id: g.id, name: g.external_id };
              })}
              onChange={(items) => {
                setAnimalGroupIds(items.map((i) => i.id));
              }}
            />
            <Button
              type="submit"
              className="AnimalGroupSelector-submitButton"
              variant="vivid"
              content="Done"
              color="success"
              onClick={() => {
                selectorDialogRef.current.close();
              }}
            />
          </div>
        </Dialog>
      </div>
      {children?.(dateRange)}
    </>
  );
}

AnimalGroupHeader.propTypes = {
  /**
   * Determiens whether or not the to display the loading skeletons for this component.
   */
  loading: PropTypes.bool,
  /**
   * CSS class override.
   */
  className: PropTypes.string,
  /**
   * Optional ID of an animal group used to override default behaviour.
   */
  animalGroupId: PropTypes.string,
  /**
   * Optional setter function for overriding the animal group ID.
   */
  setAnimalGroupId: PropTypes.func,
  /**
   * The ID of the barn to view animal groups for.
   */
  barnId: PropTypes.string.isRequired,
  /**
   * The ID of the organization owning barnId. Required for group creation. Optional otherwise.
   */
  orgId: PropTypes.string,
  /**
   * A function returning JSX. Passed an object with 'from' and 'to' keys.
   */
  children: PropTypes.func,
  /**
   * Turns on the ability to change the group being looked at.
   */
  allowGroupSelection: PropTypes.bool,
};

export default AnimalGroupHeader;
