import PropTypes from 'prop-types';
import { StatusEnum } from '../../utils/enums';
import useAlertStatusBadges from '../../utils/hooks/useAlertStatusBadges';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor/useCensor';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import StatusBadge from '../../atoms/StatusBadge';
import StatusIcon from '../../atoms/StatusIcon';
import FeedLineStatusHeader from '../../molecules/FeedLineStatusHeader';
import './FeedLineStatusCard.scss';
import withStaffOnlyIdWrapper from '../../utils/withStaffOnlyIdWrapper';
import { FeedLineFullStatus, FeedLineRunningStatus } from '../../pages/BarnSummaryTab/enum';

function FeedLineStatusCardView({
  loading = false,
  className = '',
  isMetric = false,
  nofeedTimeInSeconds,
  activeFaultCodes = [],
  feedLineName = '',
  feedLineLink = '',
  isFeedLineFull = FeedLineFullStatus.Unknown,
  isAugerRunning = FeedLineRunningStatus.Unknown,
  isCalculating = false,
  lastFeedingEventStartInSeconds = 0,
  weightInGrams = 0,
  durationInSeconds = 0,
  deviceList = [],
  calibrationStatus,
  feedLineId = '',
}) {
  const { censor } = useCensor();
  const { getAlertStatusBadges } = useAlertStatusBadges();
  const { badges: alertStatusBadges } = getAlertStatusBadges({
    nofeedTimeInSeconds,
    activeFaultCodes: Array.from(new Set(activeFaultCodes.map((f) => f.code)).values()),
    deviceList,
  });

  if (loading) {
    return (
      <Card
        loading={loading}
        className={`FeedLineStatusCard ${className}`}
        contentClassName="FeedLineStatusCard-content"
        accentLocation="left"
      >
        <div className="FeedLineStatusCard-feedLineInfo">
          <div className="FeedLineStatusCard-header">
            <LoadingSkeleton className="FeedLineStatusCard-name--loading" />
            <Button
              className="FeedLineStatusCard-viewDetails"
              disabled={loading}
              variant="text"
              color="success"
              content="View Details"
            />
          </div>
          <FeedLineStatusHeader loading={loading} />
        </div>
      </Card>
    );
  }

  const renderAlertStatusBadges = () => {
    if (!deviceList.length)
      return (
        <div className="FeedLineStatusCard-statusBadges">
          <StatusBadge status={'offline'} icon={'logo'} text={'No Sensor Set Up'} />
        </div>
      );

    return (
      <div className="FeedLineStatusCard-statusBadges">
        {alertStatusBadges.map((badge) => (
          <StatusBadge key={badge.text} status={badge.status} icon={badge.icon} text={badge.text} />
        ))}
      </div>
    );
  };

  let status = StatusEnum.Inactive;
  if (deviceList.length > 0) {
    status = StatusEnum.Success;
  }
  if (nofeedTimeInSeconds !== null) {
    if (nofeedTimeInSeconds < 0) {
      status = StatusEnum.Warning;
    } else if (nofeedTimeInSeconds > 0) {
      status = StatusEnum.Error;
    }
  }
  const IdWrappedName = withStaffOnlyIdWrapper(censor(feedLineName, censorTypes.feedline));
  return (
    <Card
      className={`FeedLineStatusCard ${className}`}
      contentClassName="FeedLineStatusCard-content"
      accentLocation="left"
      status={status}
    >
      <div className="FeedLineStatusCard-feedLineInfo">
        <div className="FeedLineStatusCard-header">
          <div className="FeedLineStatusCard-name">
            {status !== StatusEnum.Success ? <StatusIcon status={status} /> : null}
            <IdWrappedName data={feedLineId} hoverText="Copy Feed Line ID" />
            {renderAlertStatusBadges()}
          </div>
          <Button
            className="FeedLineStatusCard-viewDetails"
            variant="text"
            color="success"
            content="View Details"
            linkTo={feedLineLink}
          />
        </div>
        <FeedLineStatusHeader
          isMetric={isMetric}
          isFeedLineFull={isFeedLineFull}
          isAugerRunning={isAugerRunning}
          isCalculating={isCalculating}
          lastFeedingEventStartInSeconds={lastFeedingEventStartInSeconds}
          weightInGrams={weightInGrams}
          durationInSeconds={durationInSeconds}
          calibrationStatus={calibrationStatus}
        />
      </div>
    </Card>
  );
}

FeedLineStatusCardView.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  isMetric: PropTypes.bool,
  nofeedTimeInSeconds: PropTypes.number,
  activeFaultCodes: PropTypes.array,
  feedLineName: PropTypes.string,
  feedLineLink: PropTypes.string,
  isFeedLineFull: PropTypes.string,
  isAugerRunning: PropTypes.string,
  isCalculating: PropTypes.bool,
  lastFeedingEventStartInSeconds: PropTypes.number,
  weightInGrams: PropTypes.number,
  durationInSeconds: PropTypes.number,
  deviceList: PropTypes.array,
  calibrationStatus: PropTypes.string,
  feedLineId: PropTypes.string,
};

export default FeedLineStatusCardView;
