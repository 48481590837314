import PropTypes from 'prop-types';
import { useCensor, censorTypes } from '../../../utils/hooks/useCensor/useCensor';
import FeedFloDropDown from '../../../atoms/FeedFloDropDown';
import './FeedDeskSettings.scss';
import ToggleSwitch from '../../../atoms/ToggleSwitch';
import { FeedDeskEmailFormat } from './enum';
import ComboboxMulti from '../../../atoms/ComboboxMulti';

function FeedDeskSettingsView({
  loading = false,
  allBarns = [],
  emailsEnabled,
  emailTimeHour,
  allBarnsSelected,
  selectedBarns,
  emailFormat,
  emptyEmails,
  sendLeadTimeDays,
  onChangeEmailsEnabled = () => {},
  onChangeEmailTimeHour = () => {},
  onChangeAllBarnsSelected = () => {},
  onChangeSelectedBarns = () => {},
  onChangeEmailFormat = () => {},
  onChangeEmptyEmails = () => {},
  onSendLeadTimeDays = () => {},
}) {
  const { censor } = useCensor();

  return (
    <div className={`FeedDeskSettings ${loading ? 'FeedDeskSettings--loading' : null}`}>
      <div className="FeedDeskSettings-sectionTitle">
        Feed Forecast Emails{' '}
        <ToggleSwitch className="FeedDeskSettings-toggle" isActive={emailsEnabled} onToggle={onChangeEmailsEnabled} />
      </div>
      <div className="FeedDeskSettings-section">
        <FeedFloDropDown
          loading={loading}
          label="Preferred Email Delivery Time"
          defaultTitle="--"
          list={Array.from({ length: 24 }, (_, i) => i).map((h) => {
            return {
              id: h,
              name: `${h % 12 === 0 ? 12 : h % 12}:00${h < 12 ? 'am' : 'pm'}`,
              selected: h === emailTimeHour,
            };
          })}
          disabled={!emailsEnabled}
          onChange={(e) => {
            onChangeEmailTimeHour(e.id);
          }}
        />
        <FeedFloDropDown
          loading={loading}
          label="Send Email X Days Before the Order is Needed"
          defaultTitle="--"
          list={Array.from({ length: 7 }, (_, i) => i + 1).map((d) => {
            return {
              id: d,
              name: `${d == 1 ? '1 Day' : `${d} Days`}`,
              selected: d === sendLeadTimeDays,
            };
          })}
          disabled={!emailsEnabled}
          onChange={(e) => {
            onSendLeadTimeDays(e.id);
          }}
        />
        <div className="FeedDeskSettings-row">
          <div className="FeedDeskSettings-row-label">Select All Barns:</div>
          <ToggleSwitch
            className="FeedDeskSettings-toggle"
            isActive={allBarnsSelected}
            onToggle={onChangeAllBarnsSelected}
            disabled={!emailsEnabled}
          />
        </div>
        <ComboboxMulti
          items={allBarns?.map((b) => {
            return { name: censor(b.name, censorTypes.barn), id: b.id, selected: selectedBarns?.includes(b.id) };
          })}
          label={`Selected Barns`}
          itemNameSingular="barn"
          itemNamePlural="barns"
          loading={loading}
          onChange={(e) => {
            if (!loading) onChangeSelectedBarns(e);
          }}
          value={selectedBarns}
          disabled={allBarnsSelected || !emailsEnabled}
          allowSelectAll={false}
        />
        <FeedFloDropDown
          loading={loading}
          label="Email Format"
          defaultTitle="Select Email Format"
          list={[
            {
              id: FeedDeskEmailFormat.Single,
              name: 'One Email Per Barn',
              selected: emailFormat === FeedDeskEmailFormat.Single,
            },
            {
              id: FeedDeskEmailFormat.Digest,
              name: 'Daily Digest',
              selected: emailFormat === FeedDeskEmailFormat.Digest,
            },
          ]}
          onChange={(data) => {
            onChangeEmailFormat(data.id);
          }}
          disabled={!emailsEnabled}
        />
        <div className="FeedDeskSettings-row">
          <div className="FeedDeskSettings-row-label">Receive Emails on Days With No Orders:</div>
          <ToggleSwitch
            className="FeedDeskSettings-toggle"
            isActive={emptyEmails}
            onToggle={onChangeEmptyEmails}
            disabled={!emailsEnabled}
          />
        </div>
      </div>
    </div>
  );
}

FeedDeskSettingsView.propTypes = {
  loading: PropTypes.bool,
  allBarns: PropTypes.array,
  emailsEnabled: PropTypes.bool,
  emailTimeHour: PropTypes.number,
  allBarnsSelected: PropTypes.bool,
  selectedBarns: PropTypes.array,
  emailFormat: PropTypes.string,
  emptyEmails: PropTypes.bool,
  sendLeadTimeDays: PropTypes.number,
  onChangeEmailsEnabled: PropTypes.func,
  onChangeEmailTimeHour: PropTypes.func,
  onChangeAllBarnsSelected: PropTypes.func,
  onChangeSelectedBarns: PropTypes.func,
  onChangeEmailFormat: PropTypes.func,
  onChangeEmptyEmails: PropTypes.func,
  onSendLeadTimeDays: PropTypes.func,
};

export default FeedDeskSettingsView;
